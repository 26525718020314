import React from 'react'
import {
    withStyles,
    Typography,
} from '@material-ui/core'
import { Mail, Drafts as MailOpen } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import styles from './styles'

const TaskEmails = props => {
    const { classes, task } = props;
    const { i18n } = useTranslation();

    if (!task.taskEmails || (task.taskEmails && task.taskEmails.length === 0)) return null

    return (
        <div style={{ padding: 4 }}>
            <div className={classes.emails__container} style={isMobile ? { margin: 0 } : {}}>
                {task.taskEmails && task.taskEmails.length > 0 && task.taskEmails.map((email, i) => (
                    <div key={i} className={`${classes.email__container} ${email.read ? '' : classes['email__container--unread']}`}>
                        <a key={i}
                            className={classes.link}
                            href={`/${i18n.language}/emails/inbox/${email.emailId}`}
                            target='_blank'
                            rel="noopener noreferrer" >
                            {email.read ?
                                <MailOpen style={{
                                    width: 18,
                                    height: 18
                                }} />
                                :
                                <Mail style={{
                                    width: 18,
                                    height: 18
                                }} />
                            }
                        </a>
                        <div className={`${classes.email__text__container} ${email.read ? '' : classes['email__text__container--unread']}`}>
                            <Typography variant='body1' color='textPrimary'>{email.subject}</Typography>
                            <Typography variant='body2' color='textSecondary' style={{ marginRight: 5 }}>{email.senderWithFormat ? email.senderWithFormat.split('<')[0] : email.sender} - {moment.utc(email.timestamp).format('MMMM DD hh:mm a')}</Typography>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default withStyles(styles)(TaskEmails);