import {
    SET_RECALADAS
} from '../actions/recaladasActions.js'

import {
    CREATE_RECALADA,
    UPDATE_RECALADA
} from 'actions/recaladaActions'

function recaladas(state = {}, action) {
    let itineraryIndex = -1;
    switch (action.type) {
        case SET_RECALADAS:
            return action.recaladas.sort((a, b) => {
                if (a.ETA < b.ETA) return -1;
                if (a.ETA > b.ETA) return 1;
                return 0
            })
        case CREATE_RECALADA:
            return [
                ...state,
                { ...action.recalada }
            ].sort((a, b) => {
                if (a.ETA < b.ETA) return -1;
                if (a.ETA > b.ETA) return 1;
                return 0
            });
        case UPDATE_RECALADA:
            itineraryIndex = state.findIndex(i => i.recaladaId === action.recalada.itineraryId)
            if (itineraryIndex >= 0) {
                return [
                    ...state.slice(0, itineraryIndex),
                    {
                        ...state[itineraryIndex],
                        ...action.recalada
                    },
                    ...state.slice(itineraryIndex + 1),
                ].sort((a, b) => {
                    if (a.ETA < b.ETA) return -1;
                    if (a.ETA > b.ETA) return 1;
                    return 0
                });
            }
            return state;

        default:
            return state;
    }
}

export default recaladas