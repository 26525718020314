import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next'
import {
	withStyles,
	withWidth,
	Typography
} from '@material-ui/core';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect'
import {
	Box,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Badge
} from '@material-ui/core';

// Material icons
import {
	DirectionsBoat,
	EmailOutlined,
	CalendarTodayOutlined,
	SettingsOutlined as SettingsIcon,
	MoveToInboxOutlined,
	DeleteOutlineOutlined,
	Input as InputIcon,
	Business,
	Event,
	FileCopy,
	Assignment,
	Face,
	People,
	HomeWork
} from '@material-ui/icons';

import FooterLanguageSelector from 'components/FooterLanguageSelector'
// Component styles
import styles from './styles';

import auth from 'authentication/auth';

//Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as EmailsActions from 'actions/emailsActions'



class Sidebar extends Component {

	state = {
		isSettingPath: false
	}

	componentWillMount() {
		const { location } = this.props

		let parts = location.pathname.split("/"),
			url = parts[parts.length - 1];

		if (url === 'settings' || parts[parts.length - 2] === 'settings') {
			this.setState({
				isSettingPath: parts[parts.length - 1] === 'recaladas' ? false : true,
				isAccountPath: false
			})
		} else {
			this.setState({
				isSettingPath: false
			})
		}
	}

	handleSignOut = () => {
		const { match } = this.props

		auth.signOut().then(() => {
			this.props.history.push(`${match.params.port}/signIn`);
		}).catch(err => console.log(err));
	}

	render() {
		const { classes,
			className,
			match, t,
			onToggleSidebar,
			width,
			emailsCounters, account,
			currentPort } = this.props,
			{ isSettingPath } = this.state;
		let lang = match.params.lang,
			isEmailPath = match.params.folder ? true : false;

		const rootClassName = classNames(classes.root, className);

		if (!account) return null

		return (
			<nav className={rootClassName}>
				<div className={classes.logoWrapper}>
					<Link
						className={classes.logoLink}
						to="/"
					>
						<img
							height="70"
							alt="CTSA"
							className={classes.logoImage}
							src="/images/logos/logoCTSA.jpg"
						/>
					</Link>
				</div>
				<Divider className={classes.profileDivider} />
				{account.profileId === 1 &&
					<List
						component="div"
						disablePadding
					>
						<ListItem
							className={classes.listItem}
							onClick={() => {
								onToggleSidebar()
								this.props.handleChangePort()
							}}
						>
							<ListItemIcon className={classes.listItemIcon}>
								<Business />
							</ListItemIcon>
							<ListItemText
								classes={{ primary: classes.listItemText }}
								primary={currentPort.name}
							/>
						</ListItem>
					</List>
				}
				{!isMobile && width !== 'xs' && account.profileId === 1 &&
					<Divider className={classes.profileDivider} />
				}
				<List
					component="div"
					disablePadding
				>
					<ListItem
						activeClassName={classes.activeListItem}
						className={classes.listItem}
						component={NavLink}
						to={`/${lang}/${currentPort.code}/tasks`}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<CalendarTodayOutlined />
						</ListItemIcon>
						<ListItemText
							classes={{ primary: classes.listItemText }}
							primary={t("Tasks")}
						/>
					</ListItem>
					<ListItem
						activeClassName={classes.activeListItem}
						className={classes.listItem}
						component={NavLink}
						to={`/${lang}/${currentPort.code}/recaladas`}
					>
						<ListItemIcon className={classes.listItemIcon}>
							{/* <Badge badgeContent={emailsCounters.totalUnreadAssignedToShips} color="primary"> */}
							<DirectionsBoat />
							{/* </Badge> */}
						</ListItemIcon>
						<ListItemText
							classes={{ primary: classes.listItemText }}
							primary={t('Ships')}
						/>
					</ListItem>
					<ListItem
						className={classes.listItem}
						component={NavLink}
						onClick={onToggleSidebar}
						to={`/${lang}/${currentPort.code}/emails/inbox`}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<Badge badgeContent={emailsCounters.totalUnreadEmails} color="primary">
								<EmailOutlined />
							</Badge>
						</ListItemIcon>
						<ListItemText
							classes={{ primary: classes.listItemText }}
							primary={t("Emails")}
						/>
					</ListItem>


					{/* Submenu para los emails*/}

					{isEmailPath &&
						<List component="div" disablePadding>
							<ListItem
								activeClassName={classes.activeListItem}
								className={classes.nested}
								component={NavLink}
								onClick={onToggleSidebar}
								to={`/${lang}/${currentPort.code}/emails/inbox`}
							>
								<ListItemIcon className={classes.listItemIcon}>
									<MoveToInboxOutlined />
								</ListItemIcon>
								<ListItemText >
									<div className={classes.listItemTextFlex}>
										<Typography color='textPrimary'> {t('Inbox')}</Typography>
										<Typography variant='body2' color='textSecondary'> {emailsCounters.totalUnreadInboxEmails || ''}</Typography>
									</div>
								</ListItemText>
							</ListItem>
							<ListItem
								activeClassName={classes.activeListItem}
								className={classes.nested}
								component={NavLink}
								onClick={onToggleSidebar}
								to={`/${lang}/${currentPort.code}/emails/trash`}>
								<ListItemIcon className={classes.listItemIcon}>
									<DeleteOutlineOutlined />
								</ListItemIcon>
								<ListItemText >
									<div className={classes.listItemTextFlex}>
										<Typography color='textPrimary'> {t('Trash')}</Typography>
										<Typography variant='body2' color='textSecondary'> {emailsCounters.totalUnreadTrashEmails || ''}</Typography>
									</div>
								</ListItemText>
							</ListItem>
						</List>
					}

					<Divider className={classes.listDivider} />

					<ListItem
						activeClassName={classes.activeListItem}
						className={classes.listItem}
						component={NavLink}
						to={`/${lang}/${currentPort.code}/settings/recaladas`}>
						<ListItemIcon className={classes.listItemIcon}>
							<Event />
						</ListItemIcon>
						<ListItemText
							classes={{ primary: classes.listItemText }}
							primary={t("Recaladas")} >
						</ListItemText>
					</ListItem>

					<ListItem
						//activeClassName={classes.activeListItem}
						className={classes.listItem}
						onClick={() => {
							this.setState({
								isSettingPath: !isSettingPath
							})
						}}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<SettingsIcon />
						</ListItemIcon>
						<ListItemText
							classes={{ primary: classes.listItemText }}
							primary={t("Settings")}
						/>
					</ListItem>

					{/* Settings submenu */}

					{isSettingPath &&
						<List component="div" disablePadding>
							<ListItem
								activeClassName={classes.activeListItem}
								className={classes.nested}
								component={NavLink}
								onClick={onToggleSidebar}
								to={`/${lang}/${currentPort.code}/settings/ships`}
							>
								<ListItemIcon className={classes.listItemIcon}>
									<DirectionsBoat />
								</ListItemIcon>
								<ListItemText >
									<Typography color='textPrimary'> {t('Ships')}</Typography>
								</ListItemText>
							</ListItem>
							<ListItem
								activeClassName={classes.activeListItem}
								className={classes.nested}
								component={NavLink}
								onClick={onToggleSidebar}
								to={`/${lang}/${currentPort.code}/settings/templates`}
							>
								<ListItemIcon className={classes.listItemIcon}>
									<FileCopy />
								</ListItemIcon>
								<ListItemText >
									<Typography color='textPrimary'> {t('Templates')}</Typography>
								</ListItemText>
							</ListItem>
							<ListItem
								activeClassName={classes.activeListItem}
								className={classes.nested}
								component={NavLink}
								onClick={onToggleSidebar}
								to={`/${lang}/${currentPort.code}/settings/services`}
							>
								<ListItemIcon className={classes.listItemIcon}>
									<Assignment />
								</ListItemIcon>
								<ListItemText >
									<Typography color='textPrimary'> {t('Services')}</Typography>
								</ListItemText>
							</ListItem>
							<ListItem
								activeClassName={classes.activeListItem}
								className={classes.nested}
								component={NavLink}
								onClick={onToggleSidebar}
								to={`/${lang}/${currentPort.code}/settings/entities`}
							>
								<ListItemIcon className={classes.listItemIcon}>
									<HomeWork />
								</ListItemIcon>
								<ListItemText >
									<Typography color='textPrimary'> {t('Entities')}</Typography>
								</ListItemText>
							</ListItem>

						</List>
					}

					{account.profileId !== 1 ?
						<ListItem
							activeClassName={classes.activeListItem}
							className={classes.listItem}
							component={NavLink}
							onClick={onToggleSidebar}
							to={`/${lang}/${currentPort.code}/account`}
						>
							<ListItemIcon className={classes.listItemIcon}>
								<Face />
							</ListItemIcon>
							<ListItemText >
								<Typography color='textPrimary'> {account ? `${account.firstName} ${account.lastName}` : ''}</Typography>
							</ListItemText>
						</ListItem>
						:
						<ListItem
							activeClassName={classes.activeListItem}
							className={classes.listItem}
							component={NavLink}
							onClick={onToggleSidebar}
							to={`/${lang}/${currentPort.code}/users`}
						>
							<ListItemIcon className={classes.listItemIcon}>
								<People />
							</ListItemIcon>
							<ListItemText >
								<Typography color='textPrimary'> {t('Users')}</Typography>
							</ListItemText>
						</ListItem>
					}

				</List>

				{(isMobile || width === 'xs') && (
					<>
						<Divider className={classes.listDivider} />
						<List
							component="div"
							disablePadding
						>
							<ListItem
								className={classes.listItem}
								onClick={this.handleSignOut}
							>
								<ListItemIcon className={classes.listItemIcon}>
									<InputIcon />
								</ListItemIcon>
								<ListItemText
									classes={{ primary: classes.listItemText }}
									primary={t('Logout')}
								/>
							</ListItem>
						</List>
					</>
				)}
				<Box style={{
					position: 'fixed',
					bottom: 10,
					left: 10
				}}>
					<FooterLanguageSelector />
				</Box>
			</nav>
		);
	}
}

Sidebar.propTypes = {
	className: PropTypes.string,
	classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ emailsCounters, account, currentPort }) => ({
	emailsCounters,
	account,
	currentPort
});
const mapDispatchToProps = (dispatch) => ({
	EmailsActions: bindActionCreators(EmailsActions, dispatch),
})


export default
	connect(mapStateToProps, mapDispatchToProps)(
		compose(
			withWidth(),
			withRouter,
			withTranslation(),
			withStyles(styles)
		)(Sidebar)
	)
