import i18n from 'i18next'
import moment from 'moment'
import store from 'store'

export function parseHumandFrendlyDueHours(dueHoursParam, dueDateReferenceId = 1, twoLinesResponse) {
    let dueDateLabel = '',
        dueHours = parseInt(dueHoursParam),
        absDueHours = Math.abs(dueHours),
        state = store.getState(),
        sufix = '', line1 = '', line2 = '';
    sufix = state.generalParameters ? state.generalParameters.dueDateReferences.find(r => r.id === dueDateReferenceId).name : ''

    if (dueHours < 0) {
        if (absDueHours < 72) {
            line1 = `${absDueHours} ${i18n.t('hourslabel')}`
            line2 = `${i18n.t('beforelabel')} ${sufix}`

            dueDateLabel = `${absDueHours} ${i18n.t('hours before')} ${sufix}`
        } else {
            let days = parseInt(absDueHours / 24)
            line1 = `${days} ${i18n.t('dayslabel')}`
            line2 = `${i18n.t('beforelabel')} ${sufix}`

            dueDateLabel = `${days} ${i18n.t('days before')} ${sufix}`
        }
    } else if (dueHours > 0) {
        if (absDueHours < 72) {
            line1 = `${absDueHours} ${i18n.t('hourslabel')}`
            line2 = `${i18n.t('afterlabel')} ${sufix}`

            dueDateLabel = `${absDueHours} ${i18n.t('hours after')} ${sufix}`
        } else {
            let days = parseInt(absDueHours / 24)
            line1 = `${days} ${i18n.t('dayslabel')}`
            line2 = `${i18n.t('afterlabel')} ${sufix}`

            dueDateLabel = `${parseInt(absDueHours / 24)} ${i18n.t('days after')} ${sufix}`
        }
    } else if (dueHours === 0) {
        line1 = `${i18n.t('attimelabel')}`
        line2 = `${i18n.t('oflabel')} ${sufix}`

        dueDateLabel = `${i18n.t('At the time of')} ${sufix}`
    }
    if (!twoLinesResponse) {
        return dueDateLabel
    } else {
        return {
            line1,
            line2
        }
    }
}

export function formatRecaladaTaskImportantDate(recalada, dueDateReferenceId = 1) {
    let state = store.getState(),
        dateReference = state.generalParameters && state.generalParameters.dueDateReferences ? state.generalParameters.dueDateReferences.find(r => r.id === dueDateReferenceId) : null,
        prefix = '',
        date = null;
    prefix = dateReference ? dateReference.name : '';

    switch (dueDateReferenceId) {
        case 1:
            date = recalada.ETA;
            break;
        case 2:
            date = recalada.endImpoDateTime;
            break;
        case 3:
            date = recalada.endOperationsDateTime;
            break;
        case 4:
            date = recalada.departureDate;
            break;
        default:
            date = recalada.ETA;
            break;
    }
    if (date) {
        return `${prefix} ${parseHumandFrendlyDate(date)}`;
    }
    else {
        return null
    }


}

export function parseHumandFrendlyDate(date) {
    if (moment().isSame(moment(date), 'day')) {
        return `${i18n.t('Today')} ${moment(date).format('hh:mm a')}`
    } else {
        return moment(date).format('MMMM DD hh:mm a')
    }
}

export function getRefDateDefined(recalada, dueDateReferenceId = 1) {
    let refDate = null,
        dateDefined = true;
    switch (dueDateReferenceId) {
        case 1:
            refDate = recalada.ETA;
            break;
        case 2:
            refDate = recalada.endImpoDateTime;
            dateDefined = recalada.endImpoDateTime ? true : false;
            break;
        case 3:
            refDate = recalada.endOperationsDateTime;
            dateDefined = recalada.endOperationsDateTime ? true : false;
            break;
        case 4:
            refDate = recalada.departureDate;
            dateDefined = recalada.departureDate ? true : false;
            break;
        default:
            refDate = recalada.ETA;
            break;
    }
    return {
        refDate,
        dateDefined
    }
}

export function parseHumandFrendlyDueDate(recalada, dueHours, dueDateReferenceId = 1, composedResponse) {
    let { refDate, dateDefined } = getRefDateDefined(recalada, dueDateReferenceId)

    if (dateDefined) {
        let date = moment(refDate).add(dueHours, 'hours');
        if (!composedResponse) {
            if (moment().isSame(moment(date), 'day')) {
                return `${i18n.t('Today')} ${moment(date).format('hh:mm A')}`
            } else {
                return moment(date).format('MMMM DD hh:mm A')
            }
        } else {
            if (moment().isSame(moment(date), 'day')) {
                return {
                    day: i18n.t('Today'),
                    time: moment(date).format('hh:mm A')
                }
            } else {
                return {
                    day: moment(date).format('MMMM DD'),
                    time: moment(date).format('hh:mm A')
                }
            }
        }
    } else {
        return parseHumandFrendlyDueHours(dueHours, dueDateReferenceId, composedResponse)
    }

}

export function taskPastDue(recalada, dueHours, dueDateReferenceId = 1) {
    let date = null;

    switch (dueDateReferenceId) {
        case 1:
            date = recalada.ETA;
            break;
        case 2:
            date = recalada.endImpoDateTime;
            break;
        case 3:
            date = recalada.endOperationsDateTime;
            break;
        case 4:
            date = recalada.departureDate;
            break;
        default:
            date = recalada.ETA;
            break;
    }

    let dueDate = moment(date).add(dueHours, 'hours');

    return moment().isAfter(dueDate)
}

export function reminderFrendlyDueHours(dueHoursParam) {
    let dueDateLabel = '',
        dueHours = parseInt(dueHoursParam),
        absDueHours = Math.abs(dueHours),
        sufix = '';

    if (dueHours < 0) {
        if (absDueHours < 72) {
            dueDateLabel = `${absDueHours} ${i18n.t('reminder_hours_before')} ${sufix}`
        } else {
            let days = parseInt(absDueHours / 24)
            dueDateLabel = `${days} ${i18n.t('reminder_days_before')} ${sufix}`
        }
    } else if (dueHours > 0) {
        if (absDueHours < 72) {
            dueDateLabel = `${absDueHours} ${i18n.t('reminder_hours_after')} ${sufix}`
        } else {
            let days = parseInt(absDueHours / 24)
            dueDateLabel = `${days} ${i18n.t('reminder_days_after')} ${sufix}`
        }
    } else if (dueHours === 0) {
        dueDateLabel = `${i18n.t('reminder_same_time')} ${sufix}`
    }
    return dueDateLabel
}