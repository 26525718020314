import React, { useState, useEffect } from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    withStyles,
    Typography,
    IconButton,
    Grid,
    withMobileDialog,
    CircularProgress,
} from '@material-ui/core'
import {
    Close,
    Done,
    ExpandMore,
    ExpandLess,
    AttachFile
} from '@material-ui/icons'
import styles from './styles';
import compose from 'recompose/compose'
// import { useTranslation } from 'react-i18next'
import { green } from '@material-ui/core/colors';
import {
    Name,
    DueDate,
    Messages,
    Description,
    // Keywords,
    RemindersList,
    // RequiredResult,
    TaskEmails,
    Attachments,
    Users
} from './components';
import {
    useDispatch
} from 'react-redux';
import { useSelector } from 'react-redux'
import { Tasks } from 'services'
import { setTask as setTaskAction, clearTask as clearTaskAction } from 'actions/tasksActions'
import { formatRecaladaTaskImportantDate } from 'lib'
import { setTaskChecklist } from 'actions/tasksActions'

import { CheckList } from './components'



const TaskDetails = props => {
    const dispatch = useDispatch(),
        { open, onClose, classes, selectedTask, fullScreen } = props,
        [taskFetched, setTaskFetched] = useState(false),
        [fetching, setFetching] = useState(false),
        [openSentDetails, setOpenSentDetails] = useState(false),
        [openResponseDetails, setOpenResponseDetails] = useState(false),
        // { t } = useTranslation(),
        recalada = useSelector(state => (state.recalada)),
        task = useSelector(state => (state.task));

    useEffect(() => {
        if (!taskFetched && !fetching && selectedTask) {
            setFetching(true)
            dispatch(setTaskAction({ ...selectedTask }))
            Tasks.getTask({ taskId: selectedTask.taskId })
                .then(taskUpdatedResponse => {
                    let taskUpdated = taskUpdatedResponse.data.record;
                    setFetching(false)
                    setTaskFetched(true)
                    dispatch(setTaskAction({ ...taskUpdated }))
                })
        }
    }, [taskFetched, fetching, selectedTask, dispatch])


    const handleExit = () => {
        setTaskFetched(false);
        setFetching(false);
        dispatch(clearTaskAction())
    }

    const updateChecklist = (checklist) => {
        Tasks.updateTaskChecklist({
            taskId: selectedTask.taskId,
            checklist
        })
            .then(response => {
                if (response.success) {
                    dispatch(setTaskChecklist({
                        taskTemplateId: selectedTask.id,
                        serviceTemplateId: selectedTask.serviceTemplateId,
                        checklist
                    }))
                }
            })
    }

    return (
        <Dialog
            open={open || false}
            fullScreen={fullScreen}
            classes={{
                paper: classes.dialog__paper
            }}
            maxWidth='md'
            onExited={handleExit}>
            <DialogTitle
                className={classes.dialog__title}>
                <div className={classes.dialog__title__container}>
                    <div>
                        <Typography variant='h5' color='inherit'>{recalada && recalada.recaladaId ? `${recalada.ship ? recalada.ship.name : ''} V-${recalada.itineraryName}` : ''}</Typography>
                        {recalada && recalada.recaladaId && (
                            <Typography variant='h6' color='inherit'>{formatRecaladaTaskImportantDate(recalada, task ? task.dueDateReferenceId : 1)}</Typography>
                        )}
                    </div>

                    <IconButton
                        color='inherit'
                        onClick={onClose}>
                        <Close />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent classes={{
                root: classes.dialog__container
            }}>
                {task && (
                    <Grid container spacing={2} style={{ position: 'relative' }}>
                        <Grid item xs={12}>
                            <Name task={task} />
                        </Grid>
                        <Grid item xs={12}
                            className={classes.dueReminders__container}
                            style={{ padding: '0 8px' }}>
                            <DueDate task={task} recalada={recalada} fetching={fetching} />
                        </Grid>
                        {task.isAutomaticTask === 0 &&
                            <Grid item xs={12} >
                                <Description task={task} />
                            </Grid>
                        }

                        {/* TEMPORAL = DEMO */}

                        {task.isAutomaticTask === 1 && task.taskId === 2737 && <>
                            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
                                <div style={{ display: 'flex', marginBottom: '10px' }}>
                                    <Typography style={{ display: 'flex', alignItems: 'center' }}>
                                        <Done style={{ color: green[400] }} />
                                        {'Correo enviado 4 de abril 6:00am'}
                                    </Typography>
                                    {openSentDetails ?
                                        <ExpandLess onClick={() => {
                                            setOpenSentDetails(!openSentDetails)
                                        }} />
                                        :
                                        <ExpandMore onClick={() => {
                                            setOpenSentDetails(!openSentDetails)
                                        }} />
                                    }
                                </div>
                                {openSentDetails &&
                                    <div style={{ padding: '10px 32px', background: '#f4f4f4', marginBottom: '10px' }}>
                                        <Typography>
                                            <strong>{'Para:'}</strong> {'info@ciatransportadora.com'}
                                        </Typography>
                                        <Typography>
                                            <strong>{'Asunto:'}</strong> {'MOL PROSPERITY-V25424. Proyecciones de embarque'}
                                        </Typography>
                                        <Typography style={{ marginTop: '16px', whiteSpace: 'pre-line' }}>
                                            {'Saludos \n\n Solicito Proyecciones de embarque para el buque MOL PROSPERITY-V25424 con fecha estimada de arribo MIÉRCOLES 20 MAYO 06:00.'}
                                        </Typography>
                                    </div>
                                }
                                <div style={{ display: 'flex', marginBottom: '10px' }}>
                                    <Typography style={{ display: 'flex', alignItems: 'center' }}>
                                        <Done style={{ color: green[400] }} />
                                        {'Respuesta recibida 4 de abril 9:30am'}
                                    </Typography>
                                    {openResponseDetails ?
                                        <ExpandLess onClick={() => {
                                            setOpenResponseDetails(!openResponseDetails)
                                        }} />
                                        :
                                        <ExpandMore onClick={() => {
                                            setOpenResponseDetails(!openResponseDetails)
                                        }} />
                                    }
                                </div>
                                {openResponseDetails &&
                                    <div style={{ padding: '10px 32px', background: '#f4f4f4', marginBottom: '10px' }}>
                                        <Typography>
                                            <strong>{'De:'}</strong> {'info@ciatransportadora.com'}
                                        </Typography>
                                        <Typography>
                                            <strong>{'Asunto:'}</strong> {'RE: MOL PROSPERITY-V25424. Proyecciones de embarque'}
                                        </Typography>
                                        <Typography style={{ marginTop: '16px', whiteSpace: 'pre-line' }}>
                                            {'Saludos \n\n Se adjunta documento de proyecciones de embarque para buque MOL PROSPERITY-V25424 con fecha estimada de arribo MIÉRCOLES 20 MAYO 06:00. \n\n'}
                                        </Typography>
                                        <Typography style={{ display: 'flex', alignItems: 'center' }}>
                                            <AttachFile />
                                            {'Estimado de contenedores para carga y descarga.pdf'}
                                        </Typography>
                                    </div>
                                }
                                <div style={{ display: 'flex', marginBottom: '10px' }}>
                                    <Typography style={{ display: 'flex', alignItems: 'center' }}>
                                        <Done style={{ color: green[400] }} />
                                        {'Revisada por Juan Carlos Castillo  4 de abril 11:00am'}
                                    </Typography>
                                </div>
                            </Grid>
                        </>}

                        {task.taskId === 2674 && <>
                            <Grid item xs={12}>
                                <Typography style={{ display: 'flex', alignItems: 'center' }}>
                                    <Done style={{ color: green[400] }} />
                                    {'Completada por Juan Carlos Castillo 5 de abril 8:35am'}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} style={{ marginLeft: '22px' }}>
                                <Typography variant='body1' style={{ display: 'flex', alignItems: 'center' }}>
                                    <strong>{'Código aviso Sociedad Portuaria'}</strong>
                                </Typography>
                                <Typography style={{ display: 'flex', alignItems: 'center' }}>
                                    {'54693-p'}
                                </Typography>
                            </Grid>
                        </>}

                        {/* FIN DEMO */}

                        {task.isAutomaticTask === 0 && <>
                            {task.complete === 0 &&
                                < Grid item xs={12}>
                                    <Users task={task} />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <RemindersList
                                    task={task} />
                            </Grid>
                            <Grid item xs={12} >
                                <CheckList updateChecklist={updateChecklist} />
                            </Grid>
                        </>}

                        {!fetching ?
                            <>
                                <Grid item xs={12}>
                                    <Attachments task={task} />
                                </Grid>
                                <Grid item xs={12} style={{ padding: 0 }}>
                                    <TaskEmails task={task} />
                                </Grid>
                                {task.isAutomaticTask === 0 &&
                                    <Grid item xs={12}>
                                        <Messages task={task} />
                                    </Grid>
                                }
                            </>
                            :
                            <Grid item xs={12}>
                                <div className={classes.loading__container}
                                    style={{ height: 30 }}>
                                    <CircularProgress style={{ fontSize: '20px' }} />
                                </div>
                            </Grid>
                        }
                    </Grid>
                )}
                {!task && (
                    <div className={classes.loading__container}>
                        <CircularProgress />
                    </div>
                )}
            </DialogContent>
        </Dialog >
    )
}

export default compose(
    withMobileDialog(),
    withStyles(styles)
)(TaskDetails)
