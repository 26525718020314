import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import auth from '../../../../authentication/auth';
import { isMobile } from 'react-device-detect'
import { withStyles, withWidth } from '@material-ui/core';

import {
  Badge,
  IconButton,
  Popover,
  Toolbar,
  Typography
} from '@material-ui/core';

import {
  Menu as MenuIcon,
  Close as CloseIcon,
  NotificationsOutlined as NotificationsIcon,
  Input as InputIcon,
  ArrowBackIosOutlined
} from '@material-ui/icons';

import { getNotifications } from 'services/notification';
import { Emails } from 'services';

import { NotificationList } from './components';

import styles from './styles';

//Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as EmailsActions from 'actions/emailsActions'



class Topbar extends Component {
  signal = true;

  state = {
    notifications: [],
    notificationsLimit: 4,
    notificationsCount: 0,
    notificationsEl: null
  };

  async getNotifications() {
    try {
      const { notificationsLimit } = this.state;

      const { notifications, notificationsCount } = await getNotifications(
        notificationsLimit
      );

      if (this.signal) {
        this.setState({
          notifications,
          notificationsCount
        });
      }
    } catch (error) {
      return;
    }
  }

  componentWillMount() {
    Emails.getUnreadEmailsCounters()
      .then(countersResponse => {
        if (countersResponse.status === 'success') {
          this.props.EmailsActions.setEmailsCounters(countersResponse.data)
        }
      })
  }

  componentDidMount() {
    this.signal = true;
    this.getNotifications();
  }

  componentWillUnmount() {
    this.signal = false;
  }

  handleSignOut = () => {
    this.setState({
      openConfirmationLogout: false
    }, () => {
      auth.signOut().then(() => {
        this.props.history.push('/signIn');
      }).catch(err => console.log(err));
    })
  }

  // handleSignOut = () => {
  //   const { history } = this.props;

  //   localStorage.setItem('isAuthenticated', false);
  //   history.push('/sign-in');
  // };

  handleShowNotifications = event => {
    this.setState({
      notificationsEl: event.currentTarget
    });
  };

  handleCloseNotifications = () => {
    this.setState({
      notificationsEl: null
    });
  };
  goBack = () => {
    const { goBackUrl } = this.props;
    this.props.history.push(goBackUrl)
  }

  render() {
    const {
      classes,
      className,
      title,
      isSidebarOpen,
      onToggleSidebar,
      width,
      showBackMenuButton,
      account,
      currentPort
    } = this.props;
    const { notifications, notificationsCount, notificationsEl, } = this.state;

    const rootClassName = classNames(classes.root, className);
    const showNotifications = Boolean(notificationsEl);

    if (!account) return null

    return (
      <Fragment>
        <div className={rootClassName}>
          <Toolbar className={classes.toolbar} style={isMobile || width === 'xs' ? { display: 'flex', justifyContent: 'space-between' } : {}}>
            <IconButton
              className={classes.menuButton}
              onClick={!showBackMenuButton ? onToggleSidebar : this.goBack}
              variant="text"
            >
              {!showBackMenuButton ?
                <>
                  {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
                </>
                :
                <ArrowBackIosOutlined />
              }
            </IconButton>
            <div className={classes.title__section} style={isMobile || width === 'xs' ? { alignItems: 'center' } : {}}>
              <Typography
                variant={isMobile || width === 'xs' ? "h4" : "h3"}
              >
                {currentPort.name}
              </Typography>
              <Typography
                className={classes.subTitle}
                variant="h5"
              >
                {title}
              </Typography>
            </div>
            <IconButton
              className={isMobile || width === 'xs' ? '' : classes.notificationsButton}
              onClick={this.handleShowNotifications}
            >
              <Badge
                badgeContent={notificationsCount}
                color="primary"
                variant="dot"
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
            {!isMobile && width !== 'xs' && (
              <IconButton
                className={classes.signOutButton}
                onClick={this.handleSignOut}
              >
                <InputIcon />
              </IconButton>
            )}
          </Toolbar>
        </div>
        <Popover
          anchorEl={notificationsEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          onClose={this.handleCloseNotifications}
          open={showNotifications}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <NotificationList
            notifications={notifications}
            onSelect={this.handleCloseNotifications}
          />
        </Popover>
      </Fragment>
    );
  }
}

Topbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isSidebarOpen: PropTypes.bool,
  onToggleSidebar: PropTypes.func,
  title: PropTypes.string
};

Topbar.defaultProps = {
  onToggleSidebar: () => { }
};

const mapStateToProps = ({
  emailsCounters,
  account,
  currentPort }) => ({
    emailsCounters,
    account,
    currentPort
  });
const mapDispatchToProps = (dispatch) => ({
  EmailsActions: bindActionCreators(EmailsActions, dispatch),
})


export default connect(mapStateToProps, mapDispatchToProps)(compose(
  withWidth(),
  withRouter,
  withStyles(styles)
)(Topbar));
