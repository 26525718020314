import React, { useState, useEffect } from 'react';

// Material helpers
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import PerfectScrollbar from 'react-perfect-scrollbar';
import compose from 'recompose/compose';
import { connect } from 'react-redux'


// Material components
import { 
	Typography,
	withWidth,
	Table, 
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Divider,
	ListItem,
	List,
	Link,
	Button
} from '@material-ui/core';

import { Face } from '@material-ui/icons'

// Shared components
import { Portlet, PortletContent } from 'components';
import UserPortFilter from 'components/UserPortFilter';



// Component styles
const styles = theme => ({
	root: {},
  details: {
    display: 'flex'
  },
  info: {},
  locationText: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  dateText: {
    color: theme.palette.text.secondary
  },
  avatar: {
    marginLeft: 'auto',
    height: '110px',
    width: '110px',
    flexShrink: 0,
    flexGrow: 0
  },
  progressWrapper: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  users__header: {
	background: theme.palette.primary.main
  },
  tableCell__header: {
    whiteSpace: 'nowrap',
    padding: '10px',
    color: '#fff'
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  tableRow: {
    height: '34px',
    cursor: 'pointer'
  },
  tableCell: {
    whiteSpace: 'nowrap',
    padding: '10px'
  },
})


const UsersTable = props => {
	const { classes, t, width, users, currentPort, account, generalParameters } = props,
		[selectedUsers, setSelectedUsers] = useState([]),
		[selectedPort, setSelectedPort] = useState(null);

	useEffect (()=>{
		if(users && selectedPort){
			let newUsers = [...users];

			if (selectedPort && selectedPort !== 'All') {
				newUsers = newUsers.filter(user => user.portCode === selectedPort)
			}
			setSelectedUsers(newUsers)
		} else {
			if(users && currentPort){
				setSelectedUsers(users.filter(user => user.portCode === currentPort.code))
			}
		}
	}, [users, selectedPort, currentPort])

	if(!users) return null

	const handlePortFilter = (port) => {
        let newUsers = [...users];

        if (port && port !== 'All') {
            newUsers = newUsers.filter(user => user.portCode === port)
        }

		setSelectedUsers(newUsers)
		setSelectedPort(port)
    }


	return (
		<>
			{users && users.length > 0 &&
				<div style={{
					padding: isMobile ? '16px 8px' : '0 0 16px 0',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center'
				}}>
					<UserPortFilter
						onChange={handlePortFilter}
					/>

					<div>
						<Button
							color="primary"
							size="small"
							variant="outlined"
							onClick={()=>props.openUserDetail(null)}
						>
							{t('New User')}
						</Button>
					</div>
				</div>
			}

			{(width !== 'xs' && !isMobile) ? (
				<Portlet>
					<PortletContent noPadding>
						<PerfectScrollbar>
							<Table>
								<TableHead>
									<TableRow className={classes.users__header}>
										<TableCell
											align="left"
											className={classes.tableCell__header}>
											{t('Name')}
										</TableCell>
										<TableCell
											align="left"
											className={classes.tableCell__header}>
											{t('Email')}
										</TableCell>
										<TableCell
											align="left"
											className={classes.tableCell__header}>
											{t('Position')}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{selectedUsers
										.map((user, i) => (
											<TableRow
												className={classes.tableRow}
												style={account && user.id === account.id ? {background: '#e6e6e6'} : {}}
												hover
												key={i}
												onClick={()=> props.openUserDetail(user)}
											>
												<TableCell className={classes.tableCell}>
													<div className={classes.tableCellInner}>
														<Link to="#">
															<Typography variant="body1" style={{display: 'flex', alignItems: 'center'}}>
																{ account && account.id === user.id &&
																	<Face color='primary' style={{ marginRight: '4px'}}/>
																}
																<strong>{`${user.firstName} ${user.lastName}`}</strong>
															</Typography>
														</Link>
													</div>
												</TableCell>
											
												<TableCell className={classes.tableCell}>
													{user.email}
												</TableCell>

												<TableCell className={classes.tableCell}>
													{user.roleId && generalParameters ? generalParameters.roles.find(r => r.roleId === user.roleId).name : 
														generalParameters ? generalParameters.profiles.find(p => p.profileId === user.profileId).profileName : ''
													}
												</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</PerfectScrollbar>
					</PortletContent>
				</Portlet>
			) : (
					<List
						classes={{
							root: classes.list__paper
						}}>
						{selectedUsers
							.map((user, i) => (
								<React.Fragment key={i}>
									<ListItem key={i}
										onClick={()=> props.openUserDetail(user)}
										className={classes.list__item__card}
									>
										<div style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
											<div style={{display: 'flex', flexDirection: 'column'}}>
												<Typography variant="body1" >
													<strong>{`${user.firstName} ${user.lastName}`}</strong>
												</Typography>
											</div>
										</div>
										
										<Typography variant="body1">
											{user.email}
										</Typography>

										<Typography variant="body1">
											{user.roleId && generalParameters ? generalParameters.roles.find(r => r.roleId === user.roleId).name : 
												generalParameters ? generalParameters.profiles.find(p => p.profileId === user.profileId).profileName : ''
											}
										</Typography>
										
									</ListItem>
									<Divider style={{ width: '100%' }} />
								</React.Fragment>
							))}
					</List>
				)}
		</>
	);
}


const mapStateToProps = ({ account, currentPort, generalParameters }) => ({
	account,
	currentPort,
	generalParameters
});

export default connect(mapStateToProps, null)(
	compose(
		withStyles(styles),
		withTranslation(),
		withWidth()
	)(UsersTable)
)