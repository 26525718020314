export default theme => ({
    dialog__paper: {
        width: '100%',
        maxWidth: 800
    },
    dialog__title__container: {
        color: '#fff',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    dialog__title: {
        backgroundColor: theme.palette.primary.main,
    },
    dialog__container: {
        padding: theme.spacing(2)
    },
    loading__container: {
        height: 300,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    description__container: {
        padding: theme.spacing(2)
    },
    popover__content: {
        padding: 10,
        maxWidth: 300,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    popover__button: {
        marginBottom: 5,
        width: '100%',
    },
    popover__button__label: {
        justifyContent: 'flex-start'
    },
    dueReminders__container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column-reverse',
            justifyContent: 'center',
            alignItems: 'flex-start',
        }
    },
    reminders__button: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 16,
            alignSelf: 'flex-end',
            [theme.breakpoints.down('xs')]: {
                marginTop: 0
            }
        }
    }
})