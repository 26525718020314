import React, { useState, useEffect } from 'react'

import {
    // Typography,
    Grid,
    // Button,
    makeStyles,
    Divider,
    Link
} from '@material-ui/core'
import { AddAlert } from '@material-ui/icons'
import { useTranslation } from 'react-i18next';
import { Reminder, ReminderForm } from './';


import styles from './styles'


const useStyles = makeStyles(styles);

const RemindersList = props => {
    const classes = useStyles(),
        { task } = props,
        { t } = useTranslation(),
        [showReminderForm, setShowReminderForm] = useState(false),
        [selectedReminder, setSelectedReminder] = useState(null),
        [reminders, setReminders] = useState(task ? task.reminders || [] : []);

    useEffect(() => {
        let isMounted = true;
        if (task && task.reminders && isMounted) {
            setReminders(task.reminders)
        }
        return () => {
            isMounted = false
        }
    }, [task])

    const handleSelect = (reminder) => {
        setSelectedReminder(reminder)
        setShowReminderForm(true)
    }
    const handleUnselect = () => {
        setSelectedReminder(null)
    }

    return (
        <div className={classes.reminders__root}>
            <div className={classes.reminders__list}>
                <Grid container spacing={1}>
                    {reminders.length > 0 && (
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                {reminders.map((reminder, i) => (
                                    <Grid item
                                        sm={4}
                                        xs={12}
                                        key={i}
                                        style={{
                                            cursor: 'pointer'
                                        }}>
                                        <Reminder reminder={reminder}
                                            task={task}
                                            handleSelect={handleSelect}
                                            onDeleteConfirmation={() => {
                                                handleUnselect()
                                                setShowReminderForm(false)
                                            }} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    )}
                    {!showReminderForm && task.complete === 0 && (
                        <Grid item xs={12} style={{
                            display: 'flex',
                            justifyContent: 'flex-start'
                            //reminders.length === 0 ? 'center' : 'flex-start'
                        }}>
                            <Link
                                variant='body1'
                                color="primary"
                                className={classes.add__reminder__button}
                                onClick={
                                    () => {
                                        alert("No implementado aun");
                                        //TODO: Terminar
                                        //setShowReminderForm(true)
                                    }}>
                                <AddAlert className={classes.alert__icon} />
                                {t('reminders_add_reminder')}
                            </Link>
                            {/* <Button
                                variant='contained'
                                color='primary'
                                onClick={
                                    () => {
                                        setShowReminderForm(true)
                                    }}>
                                {t('reminders_add_reminder')}
                            </Button> */}
                        </Grid>
                    )}

                    {showReminderForm && (
                        <Grid item xs={12}
                            style={{
                                display: 'flex',
                                //justifyContent: 'center'
                            }}>
                            <ReminderForm
                                unselectReminder={handleUnselect}
                                reminder={selectedReminder}
                                task={task}
                                onSave={() => {
                                    setShowReminderForm(false)
                                }}
                                onCancel={() => {
                                    handleUnselect()
                                    setShowReminderForm(false)
                                }} />
                        </Grid>
                    )}
                </Grid>

            </div>

            {reminders.length > 0 && (
                <Divider style={{ marginTop: 15 }} />
            )}
        </div>
    )
    // return (
    //     <div className={classes.reminders__root}>
    //         <div className={classes.reminders__list}>
    //             <Grid container spacing={1}>
    //                 <Grid item xs={12}>
    //                     <Grid container spacing={1}>
    //                         {reminders.length === 0 && !showReminderForm && (
    //                             <Grid item xs={12}>
    //                                 <div className={classes.reminders__empty__container}>
    //                                     <Inbox className={classes.reminders__empty__container__icon} />
    //                                     <Typography variant='h6'
    //                                         style={{
    //                                             marginBottom: 10
    //                                         }}>
    //                                         {t('reminders_empty')}
    //                                     </Typography>
    //                                 </div>
    //                             </Grid>
    //                         )}
    //                         {reminders.length > 0 && reminders.map((reminder, i) => (
    //                             <Grid item
    //                                 sm={4}
    //                                 xs={12}
    //                                 key={i}
    //                                 style={{
    //                                     cursor: 'pointer'
    //                                 }}>
    //                                 <Reminder reminder={reminder}
    //                                     task={task}
    //                                     handleSelect={handleSelect}
    //                                     onDeleteConfirmation={() => {
    //                                         handleUnselect()
    //                                         setShowReminderForm(false)
    //                                     }} />
    //                             </Grid>
    //                         ))}
    //                     </Grid>
    //                 </Grid>
    //                 {!showReminderForm && (
    //                     <Grid item xs={12} style={{
    //                         display: 'flex',
    //                         justifyContent: reminders.length === 0 ? 'center' : 'flex-start'
    //                     }}>
    //                         <Button
    //                             variant='contained'
    //                             color='primary'
    //                             onClick={
    //                                 () => {
    //                                     alert("No implementado aun");
    //                                 }}>
    //                             {t('reminders_add_reminder')}
    //                         </Button>
    //                     </Grid>
    //                 )}

    //                 {showReminderForm && (
    //                     <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
    //                         <ReminderForm
    //                             unselectReminder={handleUnselect}
    //                             reminder={selectedReminder}
    //                             task={task}
    //                             onSave={() => {
    //                                 setShowReminderForm(false)
    //                             }}
    //                             onCancel={() => {
    //                                 handleUnselect()
    //                                 setShowReminderForm(false)
    //                             }} />
    //                     </Grid>
    //                 )}
    //             </Grid>

    //         </div>

    //         {reminders.length > 0 && (
    //             <Divider style={{ marginTop: 15 }} />
    //         )}
    //     </div>
    // )
}

export default RemindersList; 
