import React, { useEffect, useState } from 'react'
import {
    Typography,
    Hidden,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    List,
    ListItem,
    Grid,
    withStyles
} from '@material-ui/core'
import { CheckCircle, Warning, Done } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { taskPastDue, parseHumandFrendlyDueDate } from 'lib'
import { connect } from 'react-redux'
import moment from 'moment';
import 'moment/locale/es';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Portlet, PortletContent } from 'components';
import { useSelector } from 'react-redux'
import { green } from '@material-ui/core/colors';
import styles from './styles'


const TasksProgress = props => {
    // const { t } = useTranslation();
    const { progressBarTasks, recalada, classes } = props;
    let completedTasksCounter = progressBarTasks.filter(task => task.complete).length,
        dueTasksCounter = progressBarTasks.filter(task => !task.complete && taskPastDue(task.recalada ? task.recalada : recalada, task.dueHours, task.dueDateReferenceId)).length,
        completedPercentage = progressBarTasks ? ((completedTasksCounter * 100) / progressBarTasks.length) : 0,
        dueTaskPercentage = (dueTasksCounter / progressBarTasks.length) * 100;


    return <>
        {
            progressBarTasks.length > 0 && (completedTasksCounter > 0 || dueTasksCounter > 0) &&
            <Grid container style={{ margin: '16px 0', position: 'relative' }} alignItems='center'>
                {/* <Grid item xs={12} sm={2} className={classes.progress__bar__leyend} style={{ flexDirection: 'column' }}>
                    {completedTasksCounter > 0 && (
                        <Typography variant='body1'>
                            {`${completedTasksCounter} (${parseInt(completedPercentage)}%) ${t('Completed')}`}
                        </Typography>
                    )}
                    {dueTasksCounter > 0 && (
                        <Typography variant='body1'>
                            {`${dueTasksCounter} (${parseInt(dueTaskPercentage)}%) ${t('Past Due')}`}
                        </Typography>
                    )}
                    {(completedTasksCounter > 0 || dueTasksCounter > 0) && (
                        <Typography variant='body1'>
                            {`${progressBarTasks.length} ${t('Total')}`}
                        </Typography>
                    )}
                </Grid> */}
                <Grid item xs={12} >
                    <div className={classes.progress__bar__root} style={{
                        height: completedPercentage === dueTaskPercentage ? 12 : 10
                    }}>
                        <div className={classes.progress__bar__completed} style={{
                            width: `${completedPercentage}%`,
                            zIndex: completedPercentage <= dueTaskPercentage ? 1 : 0,
                        }} />
                        <div className={classes.progress__bar__due} style={{
                            width: `${dueTaskPercentage}%`,
                            zIndex: completedPercentage <= dueTaskPercentage ? 0 : 1,
                            height: completedPercentage === dueTaskPercentage ? 12 : 10
                        }} />
                    </div>
                </Grid>
            </Grid>
        }
    </>
}

const TasksTable = props => {
    const { tasks, classes, ship, recalada, filterType, entities, currentPort } = props,
        currentEntities = entities && currentPort ? entities[currentPort.code] : null,
        usersFilter = useSelector(state => state.usersFilter),
        [filteredTasks, setFilteredTasks] = useState([]),
        [progressBarTasks, setProgressBarTasks] = useState([]),
        { t } = useTranslation();


    useEffect(() => {
        if (tasks) {
            let filteredUsersIds = usersFilter.map(u => u.id),
                taskByType = tasks.filter(task => filterType ? (filterType === 1 ? !task.complete : task.complete) : true),
                taskToProgressBar = tasks.filter(task => task.responsiblesUsers.length === 0 ? true : (task.responsiblesUsers.filter(us => filteredUsersIds.indexOf(us.id ? us.id : us.userId) >= 0).length > 0 ? true : false)),
                taskByUsers = taskByType.filter(task => task.responsiblesUsers.length === 0 ? true : (task.responsiblesUsers.filter(us => filteredUsersIds.indexOf(us.id ? us.id : us.userId) >= 0).length > 0 ? true : false));
            setFilteredTasks(taskByUsers)
            setProgressBarTasks(taskToProgressBar)
        }
    }, [tasks, filterType, usersFilter])

    if (!tasks) return null;

    const handleSelectTask = task => {
        props.onSelect(task);
    }

    const handleSelectShip = task => {
        props.onShipSelect(task);
    }

    const renderDueDate = task => {
        let dueDate = parseHumandFrendlyDueDate(task.recalada ? task.recalada : recalada, task.dueHours, task.dueDateReferenceId, true);
        if (dueDate.day && dueDate.time) {
            return (
                <>
                    <Typography
                        color={task.complete ? 'textSecondary' : 'textPrimary'}
                        style={{ whiteSpace: 'nowrap', marginRight: 5 }}>
                        {`${dueDate.day} ${dueDate.time}`.toUpperCase()}
                    </Typography>
                    {/* <Typography color={task.complete ? 'textSecondary' : 'textPrimary'}
                        style={{ whiteSpace: 'nowrap' }}>
                        {dueDate.time}
                    </Typography> */}
                </>
            )
        } else {
            return (
                <>
                    <Typography
                        color={task.complete ? 'textSecondary' : 'textPrimary'}
                        style={{ whiteSpace: 'nowrap', marginRight: 5 }}>
                        {`${dueDate.line1} ${dueDate.line2}`}
                    </Typography>
                    {/* <Typography color={task.complete ? 'textSecondary' : 'textPrimary'}
                        style={{ whiteSpace: 'nowrap' }}>
                        {dueDate.line2}
                    </Typography> */}
                </>
            )
        }

    }

    return (
        <div className={classes.root}>

            <TasksProgress progressBarTasks={progressBarTasks} recalada={recalada} classes={classes} />

            {filteredTasks.length === 0 &&
                <div className={classes.noTasks__container}>
                    <Typography variant="h6">
                        {t('There are no tasks')}
                        {`${usersFilter.length > 2 ? ` ${t('for the {{usersLength}} selected users', { usersLength: usersFilter.length })} ` :
                            usersFilter.length === 2 ? ` ${t('for {{user1}} and {{user2}}', { user1: usersFilter[0].firstName, user2: usersFilter[1].firstName })}` :
                                ` ${t('for {{user1}}', { user1: usersFilter[0].firstName })}`}`}
                        {` ${t('today')} `}
                    </Typography>
                </div>
            }

            {filteredTasks.length > 0 && (
                <>
                    <Hidden xsDown implementation="css">
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                <PerfectScrollbar>
                                    <Table size='small'>
                                        <TableHead>
                                            <TableRow className={classes.table__header}>
                                                <TableCell
                                                    align="left"
                                                    className={classes.tableCell__header}
                                                    style={{ paddingLeft: '24px' }}>
                                                    {t('Task')}
                                                </TableCell>
                                                {!ship &&
                                                    <TableCell
                                                        align="left"
                                                        className={classes.tableCell__header}>
                                                        {t('Ship')}
                                                    </TableCell>
                                                }
                                                {/* {showTaskType &&
                                                    <TableCell
                                                        align="left"
                                                        className={classes.tableCell__header}>
                                                        {t('taskTableType')}
                                                    </TableCell>
                                                } */}
                                                <TableCell
                                                    align="left"
                                                    className={classes.tableCell__header}>
                                                    {usersFilter && usersFilter.length > 1 ? t('Responsibles') : ''}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell__header}
                                                    align="center">
                                                    {t('Status')}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredTasks.map((task, i) => {
                                                return (
                                                    <TableRow
                                                        className={`${classes.tableRow} ${task.complete ? classes['taskRow--completed'] : ''}`}
                                                        hover
                                                        key={task.taskId}
                                                        style={task.isAutomaticTask === 1 ? { background: '#f0f0f0' } : {}}
                                                    >
                                                        <TableCell
                                                            onClick={() => { handleSelectTask(task) }}
                                                            className={classes.tableCell}
                                                            style={{ maxWidth: 300, cursor: 'pointer' }}>
                                                            <div className={classes.tableCellInner}>
                                                                <Typography
                                                                    className={classes.nameText}
                                                                    variant="body1"
                                                                    color={task.isAutomaticTask === 1 || task.complete ? 'textSecondary' : 'textPrimary'}
                                                                    style={{
                                                                        fontWeight: !task.complete ? 'bold' : 'normal',
                                                                        width: '100%', whiteSpace: 'pre-wrap',
                                                                        textDecoration: 'underline',
                                                                    }}
                                                                >
                                                                    {task.name}
                                                                </Typography>
                                                                <Typography
                                                                    className={classes.typeText}
                                                                    variant="body2"
                                                                    color={task.complete || task.isAutomaticTask === 1 ? 'textSecondary' : 'textPrimary'}
                                                                >
                                                                    {task.taskType ? task.taskType.name : task.taskTypeName}
                                                                    {task.entityId && currentEntities ? ` - ${currentEntities.find(en => en.id === task.entityId).company}` : ''}
                                                                </Typography>
                                                                <Typography style={{ textTransform: 'capitalize', marginLeft: '16px' }} className={task.complete ? classes.complete__task : taskPastDue(task.recalada ? task.recalada : recalada, task.dueHours, task.dueDateReferenceId) ? classes.due__task : ''}>
                                                                    {renderDueDate(task)}
                                                                </Typography>
                                                            </div>
                                                        </TableCell>
                                                        {!ship &&
                                                            <TableCell
                                                                onClick={() => { handleSelectShip(task) }}
                                                                className={classes.tableCell}
                                                                style={{ padding: '10px 0', cursor: 'pointer' }}>
                                                                <div className={classes.tableCellInner}>
                                                                    <Typography
                                                                        variant="body1"
                                                                        style={{ textDecoration: 'underline' }}
                                                                        color={task.complete || task.isAutomaticTask === 1 ? 'textSecondary' : 'textPrimary'}
                                                                    >
                                                                        {task.ship.name} V-{task.itinerary.name}
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="body2"
                                                                        color={task.complete || task.isAutomaticTask === 1 ? 'textSecondary' : 'textPrimary'}
                                                                    >
                                                                        {moment(task.recalada.ETA).format('dddd DD MMMM').toUpperCase()}
                                                                    </Typography>
                                                                </div>
                                                            </TableCell>
                                                        }
                                                        {/* {showTaskType &&
                                                            <TableCell className={classes.tableCell} style={{ paddingTop: 10, paddingBottom: 10 }}>
                                                                <Typography
                                                                    variant="body1"
                                                                    color={task.complete ? 'textSecondary' : 'textPrimary'}
                                                                >
                                                                    {task.taskType.name}
                                                                </Typography>
                                                            </TableCell>
                                                        } */}

                                                        <TableCell className={classes.tableCell} style={{ maxWidth: 180 }}>
                                                            <Typography
                                                                color={task.complete || task.isAutomaticTask === 1 ? 'textSecondary' : 'textPrimary'}
                                                                style={{ whiteSpace: 'pre-line' }}>
                                                                {task.isAutomaticTask === 1 ? <Typography style={{ fontSize: '20px' }} component='span'><span role='img' description="automatic" aria-label='automatic'>🤖</span></Typography>
                                                                    : usersFilter && usersFilter.length > 1 && task.responsiblesUsers ?
                                                                        task.responsiblesUsers.map(user => (`${user.firstName} ${user.lastName}`)).join(', ') : ''
                                                                }
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell className={classes.tableCell} align='center' style={{ color: '#999' }}>
                                                            {task.isAutomaticTask === 1 && task.complete === 1 &&
                                                                <div>
                                                                    <Typography style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Done style={{ color: green[400] }} />
                                                                        {'Enviado 4 de abril 6:00am'}
                                                                    </Typography>
                                                                    <Typography style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Done style={{ color: green[400] }} />
                                                                        {'Respondido 4 de abril 9:30am'}
                                                                    </Typography>
                                                                    <Typography style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Done style={{ color: green[400] }} />
                                                                        {'Revisado 4 de abril 11:00am'}
                                                                    </Typography>
                                                                </div>
                                                            }

                                                            {task.isAutomaticTask === 0 && <>
                                                                {task.complete === 1 ?
                                                                    (<CheckCircle className={classes.successIcon} />)
                                                                    :
                                                                    (<>
                                                                        {taskPastDue(task.recalada ? task.recalada : recalada, task.dueHours, task.dueDateReferenceId) && (
                                                                            (<Warning className={classes.warningIcon} />)
                                                                        )}
                                                                    </>)
                                                                }
                                                            </>}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </PerfectScrollbar>
                            </PortletContent>
                        </Portlet>
                    </Hidden>
                    <Hidden smUp implementation="css">
                        <List component="div"
                            classes={{
                                root: classes.tasks__list__root
                            }}>
                            {filteredTasks.map((task, i) => (
                                <ListItem
                                    className={`${classes.nested} ${task.complete ? classes['taskRow--completed'] : ''}`}
                                    key={i}
                                    style={{ padding: 8 }}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} style={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}>
                                            <div>
                                                <Typography
                                                    onClick={() => { handleSelectTask(task) }}
                                                    variant='body1'
                                                    color={task.complete ? 'textSecondary' : 'textPrimary'}
                                                    style={{ fontWeight: !task.complete ? 'bold' : 'normal', marginBottom: 10, cursor: 'pointer' }}>
                                                    {task.name}
                                                </Typography>
                                                {!ship && <>
                                                    <Typography
                                                        variant="body1"
                                                        color={task.complete ? 'textSecondary' : 'textPrimary'}
                                                        style={{ marginBottom: 10, cursor: 'pointer', textDecoration: 'underline' }}
                                                        onClick={() => { handleSelectShip(task) }}
                                                    >
                                                        {task.ship.name} V-{task.itinerary.name}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color={task.complete ? 'textSecondary' : 'textPrimary'}
                                                    >
                                                        {moment(task.recalada.ETA).format('dddd DD MMMM').toUpperCase()}
                                                    </Typography>
                                                </>}
                                                <Typography color={task.complete ? 'textSecondary' : 'textPrimary'} style={{ whiteSpace: 'pre-line' }}>
                                                    {task.isAutomaticTask === 1 ? <Typography style={{ fontSize: '20px' }} ><span role='img' description="automatic" aria-label='automatic'>🤖</span></Typography>
                                                        : usersFilter && usersFilter.length > 1 && task.responsiblesUsers ?
                                                            task.responsiblesUsers.map(user => (`${user.firstName} ${user.lastName}`)).join(', ') : ''
                                                    }
                                                </Typography>
                                            </div>
                                            {task.complete ?
                                                (<CheckCircle className={classes.successIcon} />)
                                                :
                                                (<>
                                                    {taskPastDue(task.recalada ? task.recalada : recalada, task.dueHours, task.dueDateReferenceId) && (
                                                        <Warning className={classes.warningIcon} />
                                                    )}
                                                </>)
                                            }
                                        </Grid>
                                        {/* <Grid item xs={12} style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center'
                                        }}>
                                            {renderDueDate(task)}
                                        </Grid> */}
                                    </Grid>
                                </ListItem>
                            ))}
                        </List>
                    </Hidden>
                </>
            )
            }
        </div >
    )
}

function mapStateToProps(state) {
    return {
        entities: state.entities,
        currentPort: state.currentPort
    }
}

export default connect(mapStateToProps)(withStyles(styles)(TasksTable))
