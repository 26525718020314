import React, { useState } from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    withStyles,
    Typography,
    IconButton,
    Grid,
    withMobileDialog,
} from '@material-ui/core'

import {
    Warning,
} from '@material-ui/icons'

import { Tasks } from 'services'
import { Close } from '@material-ui/icons'
import styles from './styles';
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { clearTaskTemplate } from 'actions/taskTemplateActions'
import { deleteTaskTemplate } from 'actions/generalActions'
import { setTaskTemplateChecklist } from 'actions/taskTemplateActions'
import { ConfirmationDialog } from 'components'

import {
    Name,
    Description,
    DueHours,
    ParentTask,
    TaskTypeAndResponsible,
    Attachments,
    Entity,
    RemindersList,
    CheckList,
    RequiredResult
} from './components'


const TaskTemplateDetails = props => {

    const {
        open,
        onClose,
        classes,
        selectedTaskTemplate,
        fullScreen,
    } = props;
    const { t } = useTranslation();
    const [openConfirmation, setOpenConfirmation] = useState(false),
        [deleting, setDeleting] = useState(false),
        [deleteTemplateMessage, setDeleteTemplateMessage] = useState(''),
        [errorMessage, setErrorMessage] = useState(''),
        dispatch = useDispatch();

    const handleDelete = () => {
        setDeleting(true)

        Tasks.deleteTaskTemplate({
            taskTemplateId: selectedTaskTemplate.id
        })
            .then(response => {
                if (response.success) {
                    dispatch(clearTaskTemplate())
                    dispatch(deleteTaskTemplate({ taskTemplateId: selectedTaskTemplate.id, serviceTemplateId: selectedTaskTemplate.serviceTemplateId }))
                    setOpenConfirmation(false)

                    setDeleting(false)

                    onClose()
                } else {
                    setErrorMessage(t(response.errors.USER_MESSAGE))
                    setOpenConfirmation(false)
                    setDeleting(false)
                }
                setDeleteTemplateMessage('')
            })
    }


    const onExited = () => {
        setOpenConfirmation(false);
        setDeleting(false);
        setDeleteTemplateMessage('');
        setErrorMessage('');
    }

    const updateChecklist = (checklist) => {
        Tasks.updateTaskTemplateChecklist({
            taskTemplateId: selectedTaskTemplate.id,
            checklist
        })
            .then(response => {
                if (response.success) {
                    dispatch(setTaskTemplateChecklist({
                        taskTemplateId: selectedTaskTemplate.id,
                        serviceTemplateId: selectedTaskTemplate.serviceTemplateId,
                        checklist
                    }))
                }
            })
    }

    // const confirmDeleteTemplate=()=>{
    //     let servicesTemplatesUsed = [...generalParameters.servicesTemplates].filter(st => st.taskTemplates.filter(tt => tt.id === selectedTaskTemplate.id).length > 0)

    //     if(servicesTemplatesUsed.length > 1){
    //         setDeleteTemplateMessage(t('This task is being used in another service template.'))
    //         setOpenConfirmation(true)
    //     } else {
    //         setOpenConfirmation(true)
    //     }
    // }

    return (
        <div>
            <Dialog
                open={open || false}
                fullScreen={fullScreen}
                classes={{
                    paper: classes.dialog__paper
                }}
                maxWidth='md'
                onExited={onExited}>
                <DialogTitle
                    className={classes.dialog__title}>
                    <div className={classes.dialog__title__container}>
                        <Typography variant='h5' color='inherit'>{selectedTaskTemplate && selectedTaskTemplate.name ? selectedTaskTemplate.name : t('Task Template')}</Typography>
                        <IconButton
                            color='inherit'
                            onClick={onClose}>
                            <Close />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent classes={{
                    root: classes.dialog__container
                }}>

                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Name taskTemplate={selectedTaskTemplate} />
                        </Grid>
                        <Grid item xs={12}
                            className={classes.dueReminders__container}
                            style={{ padding: 0 }}>
                            <DueHours taskTemplate={selectedTaskTemplate} />
                        </Grid>
                        <Grid item xs={12} >
                            <Description taskTemplate={selectedTaskTemplate} />
                        </Grid>
                        <Grid item xs={12}>
                            <Entity taskTemplate={selectedTaskTemplate} />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <TaskTypeAndResponsible taskTemplate={selectedTaskTemplate} />
                        </Grid>
                        <Grid item xs={12}>
                            <ParentTask taskTemplate={selectedTaskTemplate} />
                        </Grid>
                        <Grid item xs={12}>
                            <RequiredResult />
                        </Grid>
                        <Grid item xs={12}>
                            <RemindersList taskTemplate={selectedTaskTemplate} />
                        </Grid>
                        <Grid item xs={12} >
                            <CheckList updateChecklist={updateChecklist} />
                        </Grid>
                        <Grid item xs={12} >
                            <Attachments taskTemplate={selectedTaskTemplate} />
                        </Grid>

                        <Grid item xs={12}>
                            {errorMessage &&
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Warning style={{ color: 'orange', marginRight: '8px' }} />
                                    <Typography variant='body1' color='error'>{errorMessage}</Typography>
                                </div>
                            }
                        </Grid>

                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {/* <Button
                                disabled={deleting}
                                style={{color: 'red'}}
                                onClick={confirmDeleteTemplate}>
                                {t('Delete')}
                            </Button> */}

                            <div />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>


            <ConfirmationDialog
                handleClose={() => {
                    setOpenConfirmation(false)
                }}
                onConfirm={() => {
                    handleDelete()
                }}
                loading={deleting}
                message={deleteTemplateMessage ? `${deleteTemplateMessage} \n ${t('Are you sure you wish to delete this task?')}` : t('Are you sure you wish to delete this task?')}
                cancelLabel={t('No!')}
                confirmLabel={t('Delete it!')}
                open={openConfirmation}
            />
        </div>
    )
}

function mapStateToProps(state) {
    return {
        generalParameters: state.generalParameters,
    }
}

export default connect(mapStateToProps, null)(compose(
    withMobileDialog(),
    withStyles(styles)
)(TaskTemplateDetails))