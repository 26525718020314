import React, { useState, useEffect } from 'react';


import 'moment/locale/es';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	List,
	ListItem,
	Divider,
	withStyles,
	withWidth,
	Link
} from '@material-ui/core';

import { parseHumandFrendlyDueHours } from 'lib'

import { TaskTemplateDetails } from 'components'
import RecaladaTaskTypeFilter from 'components/RecaladaTaskTypeFilter';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as taskTemplateActions from 'actions/taskTemplateActions'

// Shared components
import { Portlet, PortletContent } from 'components';

// Component styles
import styles from './styles';

const TemplateDetails = (props) => {
	const { selectedTemplate, classes, entities, currentPort } = props,
		{ t } = useTranslation(),
		{ width } = withWidth(),
		[showTaskTemplateDetails, setShowTaskTemplateDetails] = useState(false),
		[filterType, setFilterType] = useState(0),
		[tasks, setTasks] = useState(selectedTemplate ? selectedTemplate.taskTemplates : []),
		currentEntities = entities[currentPort.code]

	useEffect(() => {
		let isMounted = true
		if (selectedTemplate && isMounted) {
			let newTasks = [...selectedTemplate.taskTemplates]
			if (filterType) {
				newTasks = newTasks.filter(task => filterType === 5 ? !task.taskTypeId : task.taskTypeId === filterType)
			}
			setTasks(newTasks)
		}
		return () => {
			isMounted = false
		}
	}, [selectedTemplate, filterType])


	const handleCloseTaskTemplate = () => {
		props.taskTemplateActions.clearTaskTemplate()
		setShowTaskTemplateDetails(false)
	}

	const handleSelectTaskTemplate = (template) => {
		props.taskTemplateActions.setTaskTemplate(template)
		setShowTaskTemplateDetails(true)
	}


	const handleTypeFilter = (type) => {
		let newTasks = [...selectedTemplate.taskTemplates]
		if (type) {
			newTasks = newTasks.filter(task => type === 5 ? !task.taskTypeId : task.taskTypeId === type)
		}
		setFilterType(type)
		setTasks(newTasks)
	}

	return (
		<>
			<div style={{
				position: 'absolute',
				top: isMobile ? '90px' : '40px',
				marginLeft: isMobile ? 10 : 0
			}}>
				<RecaladaTaskTypeFilter
					onChange={handleTypeFilter}
				/>
			</div>

			{
				tasks.length === 0 && (
					<div className={classes.noTaskTemplates__container}>
						<Typography variant="h6">
							{t('There are no task templates')}
						</Typography>
					</div>
				)
			}


			{
				tasks.length > 0 && (
					<>
						{(width !== 'xs' && !isMobile) ? (
							<Portlet>
								<PortletContent noPadding>
									<PerfectScrollbar>
										<Table>
											<TableHead>
												<TableRow className={classes.recalada__header}>
													<TableCell
														align="left"
														className={classes.tableCell__header}
														style={{ paddingLeft: '24px' }}>
														{t('Name')}
													</TableCell>
													<TableCell
														align="left"
														className={classes.tableCell__header}>
														{t('Role')}
													</TableCell>
													<TableCell
														align="left"
														className={classes.tableCell__header}>
														{t('Due Hours')}
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{tasks
													.map((template, i) => (
														<TableRow
															className={classes.tableRow}
															hover
															key={i}
															onClick={() => handleSelectTaskTemplate(template)}
														>
															<TableCell className={classes.tableCell} style={{ maxWidth: 300 }}>
																<div className={classes.tableCellInner}>
																	<Link to="#">
																		<Typography style={{ width: '100%', whiteSpace: 'pre-wrap' }}
																			className={classes.nameText}
																			variant="body1"
																		>
																			{template.name}
																		</Typography>
																	</Link>
																	<Typography
																		className={classes.typeText}
																		variant="body2"
																	>
																		{template.taskType ? `${template.taskType.name} ` : template.taskTypeName}
																		{template.entityId ? ` - ${currentEntities && currentEntities.find(en => en.id === template.entityId) ? currentEntities.find(en => en.id === template.entityId).company : ''}` : ''}
																	</Typography>
																</div>
															</TableCell>
															<TableCell className={classes.tableCell}>
																<Typography
																	variant="body1"
																>
																	{template.roleName}
																</Typography>
															</TableCell>
															<TableCell className={classes.tableCell}>
																<Typography
																	variant="body1"
																>
																	{parseHumandFrendlyDueHours(template.dueHours, template.dueDateReferenceId)}
																</Typography>
															</TableCell>
														</TableRow>
													))}
											</TableBody>
										</Table>
									</PerfectScrollbar>
								</PortletContent>
							</Portlet>
						) : (
								<List
									classes={{ root: classes.list__paper }}>
									{tasks
										.map((template, i) => (
											<React.Fragment key={i}>
												<ListItem key={i}
													className={classes.list__item__card}
													onClick={() => handleSelectTaskTemplate(template)}
												>
													<Typography style={{ width: '100%', whiteSpace: 'pre-wrap' }}
														className={classes.nameText}
														variant="body1"
													>
														{template.name}
													</Typography>
													<Typography
														className={classes.typeText}
														variant="body1"
													>
														{template.taskType ? `${template.taskType.name} ` : template.taskTypeName}
														{template.entityId ? ` - ${currentEntities && currentEntities.find(en => en.id === template.entityId) ? currentEntities.find(en => en.id === template.entityId).company : ''}` : ''}
													</Typography>
													<Typography
														variant="body1"
													>
														{template.roleName}
													</Typography>
													<Typography
														variant="body1"
													>
														{parseHumandFrendlyDueHours(template.dueHours, template.dueDateReferenceId)}
													</Typography>
												</ListItem>
												<Divider style={{ width: '100%' }} />
											</React.Fragment>
										))}
								</List>
							)}
					</>
				)
			}
			<TaskTemplateDetails
				selectedTaskTemplate={props.taskTemplate}
				open={showTaskTemplateDetails}
				onClose={handleCloseTaskTemplate} />
		</>
	);
}

function mapStateToProps(state) {
	return {
		taskTemplate: state.taskTemplate,
		entities: state.entities,
		currentPort: state.currentPort
	}
}
function mapDispatchToProps(dispatch) {
	return {
		taskTemplateActions: bindActionCreators(taskTemplateActions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TemplateDetails));
