export default theme => ({
    root: {
        display: 'flex',
        margin: theme.spacing()
    },
    parent__task__container: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    select__root: {
        padding: 10
    },
    actions: {
        marginTop: theme.spacing(),
        display: 'flex'
    },
    actions__save: {
        marginRight: 10
    },
})