import React, { useState, useEffect } from 'react';
//{ useState } 
import { withRouter } from 'react-router-dom';
import moment from 'moment'
import { isMobile } from 'react-device-detect'

import {
    withStyles,
    TextField,
    Grid,
    InputAdornment,
    Button,
    Typography,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

// Component styles
import styles from './styles';

//Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as recaladaActions from 'actions/recaladaActions'
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es"
import en from "date-fns/locale/en-US"


const dateColors = [
    '#0089ff',
    '#8500ff',
    '#ffc46d',
    '#32db64'
]

const ShipDetails = props => {
    const { recalada, classes } = props,
        { t, i18n } = useTranslation(),
        [departure, setDeparture] = useState(''),
        [finImpo, setFinImpo] = useState(''),
        [ETA, setETA] = useState(''),
        [editDates, setEditDates] = useState(false),
        [finOperaciones, setFinOperaciones] = useState('');

    if (i18n.language === 'en') {
        registerLocale("en", en);
    } else {
        registerLocale("es", es);
    }

    useEffect(() => {
        let isMounted = true;
        if (recalada && isMounted) {
            setETA(moment(moment.utc(recalada.ETA).format()).format('YYYY-MM-DDTHH:mm'))
            setDeparture(recalada.departureDate ? moment(moment.utc(recalada.departureDate).format()).format('YYYY-MM-DDTHH:mm') : '')
            setFinImpo(recalada.endImpoDateTime ? moment(moment.utc(recalada.endImpoDateTime).format()).format('YYYY-MM-DDTHH:mm') : '')
            setFinOperaciones(recalada.endOperationsDateTime ? moment(moment.utc(recalada.endOperationsDateTime).format()).format('YYYY-MM-DDTHH:mm') : '')
        }
        return () => {
            isMounted = false;
        };
    }, [recalada])

    // const handleChangeETA = value => {
    //     setETA(value)
    // }

    const handleChangeDeparture = value => {
        setDeparture(value)
    }
    const handleChangeFinImpo = value => {
        setFinImpo(value)
    }
    const handleChangeFinOperaciones = value => {
        setFinOperaciones(value)
    }

    const DatePickerCustomInput = ({ placeholderText, value, onClick, label, error, disabled, dateColor }) => (
        <TextField
            disabled={disabled}
            error={error}
            label={label}
            value={value}
            placeholder={placeholderText}
            classes={{
                root: classes.datePicker__input__root
            }}
            InputProps={{
                endAdornment: dateColor ? (<InputAdornment position='end'>
                    <div style={{ width: 15, height: 15, borderRadius: '50%', backgroundColor: dateColor }}></div>
                </InputAdornment>) : null,
                inputProps: {
                    className: classes.textField__input
                }
            }}
            InputLabelProps={{
                shrink: true
            }}
            variant='outlined'
            onClick={onClick} />
    );

    const setDateColorClass = date => {
        let className = undefined;
        if (moment(date).format('DD/MM/YYYY') === moment(ETA).format('DD/MM/YYYY')) {
            className = classes.eta__date__calendar;
        }
        if (moment(date).format('DD/MM/YYYY') === moment(finImpo).format('DD/MM/YYYY')) {
            className = classes.endImpo__date__calendar;
        }
        if (moment(date).format('DD/MM/YYYY') === moment(finOperaciones).format('DD/MM/YYYY')) {
            className = classes.endOperations__date__calendar;
        }
        if (moment(date).format('DD/MM/YYYY') === moment(departure).format('DD/MM/YYYY')) {
            className = classes.departure__date__calendar;
        }
        return className;
    }

    return (
        <Grid container spacing={3} className={classes.details__container} style={isMobile ? { paddingBottom: '90px' } : {}}>
            <Grid container spacing={1} item xs={12} sm={6} style={{ borderRight: '1px solid #ccc' }}>
                <Grid item xs={12} style={{ margin: '10px 0 0' }}>
                    <Typography variant='h5' color='primary'>
                        <strong>{t('Voyage Information')}</strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography variant='h6'>{`${recalada.linerName} > ${recalada.ship.name} V-${recalada.itineraryName}`}</Typography>
                    <Typography variant='body1' className={classes.title__content}>
                        <span>{` ${moment(recalada.ETA).format('dddd DD MMMM HH:mm')}`}</span>
                    </Typography>
                    <div className={classes.details__fields__container} style={!editDates ? { background: '#f4f4f4', cursor: 'pointer' } : {}}>
                        {!editDates ?
                            <div onClick={() => setEditDates(true)} style={{ display: 'relative' }}>
                                <Typography variant='body1' className={classes.title__content}>
                                    <strong>{moment(recalada.ETA).isAfter(moment(), 'day') ? t('Fecha estimada fin importación') : t('Fin importación')}</strong><span style={{ paddingLeft: '10px' }}>{moment(finImpo).format('dddd DD MMMM HH:mm')}</span>
                                </Typography>
                                <Typography variant='body1' className={classes.title__content}>
                                    <strong>{moment(recalada.ETA).isAfter(moment(), 'day') ? t('Fecha estimada fin operaciones') : t('Fin operaciones')}</strong><span style={{ paddingLeft: '10px' }}>{moment(finOperaciones).format('dddd DD MMMM HH:mm')}</span>
                                </Typography>
                                <Typography variant='body1' className={classes.title__content}>
                                    <strong>{moment(recalada.ETA).isAfter(moment(), 'day') ? t('Fecha estimada zarpe') : t('Zarpe')}</strong><span style={{ paddingLeft: '10px' }}>{moment(departure).format('dddd DD MMMM HH:mm')}</span>
                                </Typography>
                                <div style={{ position: 'absolute', right: '8px', top: '8px', color: '#999' }}>
                                    <Edit />
                                </div>
                            </div> :
                            <Grid container spacing={2} xs={12}>
                                {/* <Grid item xs={6} sm={6}>
                                    <DatePicker
                                        locale={i18n.language}
                                        selected={ETA ? new Date(ETA) : null}
                                        onChange={date => handleChangeETA(date)}
                                        showTimeSelect
                                        timeIntervals={1}
                                        timeFormat="HH:mm"
                                        timeCaption={t("time")}
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        dayClassName={setDateColorClass}
                                        customInput={<DatePickerCustomInput
                                            label={t('ETA')}
                                            dateColor={dateColors[0]}
                                            placeholderText={i18n.language === 'en' ? "dd/mm/yyyy --:--" : "dd/mm/aaaa --:--"}
                                        />}
                                    />
                                </Grid> */}
                                <Grid item xs={6} sm={6}>
                                    <DatePicker
                                        locale={i18n.language}
                                        selected={finImpo ? new Date(finImpo) : null}
                                        onChange={date => handleChangeFinImpo(date)}
                                        minDate={ETA ? new Date(ETA) : null}
                                        showTimeSelect
                                        timeIntervals={1}
                                        timeFormat="HH:mm"
                                        timeCaption={t("time")}
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        dayClassName={setDateColorClass}
                                        customInput={<DatePickerCustomInput
                                            dateColor={dateColors[1]}
                                            label={t('Fin Importación')}
                                            // error={showValidations && invalidFields.finImpo}
                                            placeholderText={i18n.language === 'en' ? "dd/mm/yyyy --:--" : "dd/mm/aaaa --:--"}
                                        />}
                                    />

                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <DatePicker
                                        locale={i18n.language}
                                        selected={finOperaciones ? new Date(finOperaciones) : null}
                                        onChange={date => handleChangeFinOperaciones(date)}
                                        showTimeSelect
                                        timeIntervals={1}
                                        minDate={ETA ? new Date(ETA) : null}
                                        timeFormat="HH:mm"
                                        timeCaption={t("time")}
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        dayClassName={setDateColorClass}
                                        customInput={<DatePickerCustomInput
                                            dateColor={dateColors[2]}
                                            label={t('Fin Operaciones')}
                                            // error={showValidations && invalidFields.finOperaciones}
                                            placeholderText={i18n.language === 'en' ? "dd/mm/yyyy --:--" : "dd/mm/aaaa --:--"}
                                        />}
                                    />

                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <DatePicker
                                        locale={i18n.language}
                                        selected={departure ? new Date(departure) : null}
                                        onChange={date => handleChangeDeparture(date)}
                                        showTimeSelect
                                        minDate={ETA ? new Date(ETA) : null}
                                        timeFormat="HH:mm"
                                        timeCaption={t("time")}
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        dayClassName={setDateColorClass}
                                        customInput={<DatePickerCustomInput
                                            dateColor={dateColors[3]}
                                            label={t('Zarpe')}
                                            // error={showValidations && invalidFields.departure}
                                            placeholderText={i18n.language === 'en' ? "dd/mm/yyyy --:--" : "dd/mm/aaaa --:--"}
                                        />}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Button
                                        // disabled={saving}
                                        onClick={() => setEditDates(false)}>
                                        {t('Cancel')}
                                    </Button>
                                    <Button
                                        // disabled={saving}
                                        variant='contained'
                                        color='primary'
                                        onClick={() => alert('Se guardaran las fechas')}
                                    >
                                        {t('Save')}
                                        {
                                            // saving && (
                                            //     <CircularProgress color='secondary'
                                            //         style={{ marginLeft: 5 }}
                                            //         size={15} />
                                            // )
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    </div>
                </Grid>

                <Grid item xs={12} style={{ margin: '16px 0 0' }}>
                    <Typography variant='h5' color='primary'>
                        <strong>{t('Vessel Particulars')}</strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography variant='body1' className={classes.title__content}>
                        <strong style={{ marginRight: '6px' }}>{t('IMO')}</strong>
                        <span style={{ paddingLeft: '10px' }}>{recalada.ship.IMO}</span>
                    </Typography>
                    <Typography variant='body1' className={classes.title__content}>
                        <strong style={{ marginRight: '6px' }}>{t('Name')}</strong>
                        <span style={{ paddingLeft: '10px' }}>{recalada.ship.name}</span>
                    </Typography>
                    {recalada.ship.MMSI && <>
                        <Typography variant='body1' className={classes.title__content}>
                            <strong style={{ marginRight: '6px' }}>{t('MMSI')}</strong>
                            <span style={{ paddingLeft: '10px' }}>{recalada.ship.MMSI ? recalada.ship.MMSI : ''}</span>
                        </Typography>
                        <Typography variant='body1' className={classes.title__content}>
                            <strong style={{ marginRight: '6px' }}>{t('Type')}</strong>
                            <span style={{ paddingLeft: '10px' }}>{recalada.ship.type ? recalada.ship.type : ''}</span>
                        </Typography>
                        <Typography variant='body1' className={classes.title__content}>
                            <strong style={{ marginRight: '6px' }}>{t('Flag')}</strong>
                            <span style={{ paddingLeft: '10px' }}>{recalada.ship.flag ? recalada.ship.flag : ''}</span>
                        </Typography>
                        <Typography variant='body1' className={classes.title__content}>
                            <strong style={{ marginRight: '6px' }}>{t('Summer DWT')}</strong>
                            <span style={{ paddingLeft: '10px' }}>{recalada.ship.summerDWT ? `${recalada.ship.summerDWT}ton` : ''}</span>
                        </Typography>
                        <Typography variant='body1' className={classes.title__content}>
                            <strong style={{ marginRight: '6px' }}>{t('Length Overall x Breadth Extreme')}</strong>
                            <span style={{ paddingLeft: '10px' }}>{recalada.ship.lengthOverall && recalada.ship.breadthExtreme ? `${recalada.ship.lengthOverall}m x ${recalada.ship.breadthExtreme}m` : ''}</span>
                        </Typography>
                        <Typography variant='body1' className={classes.title__content}>
                            <strong style={{ marginRight: '6px' }}>{t('Year Build')}</strong>
                            <span style={{ paddingLeft: '10px' }}>{recalada.ship.yearBuild ? recalada.ship.yearBuild : ''}</span>
                        </Typography>
                    </>}
                </Grid>

            </Grid>
            <Grid container spacing={1} item xs={12} sm={6} >
                <Grid item xs={12} style={{ margin: '10px 0 0' }}>
                    <Typography variant='h5' color='primary'>
                        <strong>{t('Recalada Folder')}</strong>
                    </Typography>
                </Grid>
                {recalada.recaladaId === 110 ? <>
                    <Grid item xs={12}>
                        <Typography variant='body1' className={classes.title__content}>
                            <strong style={{ marginRight: '6px' }}>{t('Estimado de contenedores para carga y descarga')}</strong>
                            <span style={{ paddingLeft: '10px', cursor: 'pointer', textDecoration: 'underline', color: '#0767DB' }}>{'Estimado de contenedores para carga y descarga.pdf'}</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1' className={classes.title__content}>
                            <strong style={{ marginRight: '6px' }}>{t('Código aviso Sociedad Portuaria.')}</strong>
                            <span style={{ paddingLeft: '10px' }}>{'54693-p'}</span>
                        </Typography>
                    </Grid>
                </> : <>
                        <Grid item xs={12}>
                            <Typography variant='body1' className={classes.title__content}>
                                <strong style={{ marginRight: '6px' }}>{t('Estimado de contenedores para carga y descarga')}</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body1' className={classes.title__content}>
                                <strong style={{ marginRight: '6px' }}>{t('Código aviso Sociedad Portuaria.')}</strong>
                            </Typography>
                        </Grid>
                    </>}
                <Grid item xs={12}>
                    <Typography variant='body1' className={classes.title__content}>
                        <strong style={{ marginRight: '6px' }}>{t('Código aviso Sociedad Portuaria.')}</strong>
                    </Typography>
                </Grid>

                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>
            </Grid>
        </Grid >
    )
}


function mapDispatchToProps(dispatch) {
    return {
        recaladaActions: bindActionCreators(recaladaActions, dispatch),
    }
}


function mapStateToProps(state) {
    return {
        recalada: state.recalada,
        currentPort: state.currentPort
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(ShipDetails)))
