import React, { Component } from 'react'
import {
	Grid,
	//IconButton, 
	Box,
	withStyles
} from '@material-ui/core'
// import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import auth from '../../authentication/auth';
import { Authenticator, ConfirmSignUp } from 'aws-amplify-react';
import LoginForm from './LoginForm';
import ForgotPassword from './ForgotPassword'
import RequireNewPassword from './RequireNewPassword';
import { withTranslation } from 'react-i18next';
import FooterLanguageSelector from '../../components/FooterLanguageSelector'
import { connect } from 'react-redux'
import compose from 'recompose/compose';
import styles from './styles';

const authStates = [
	'signIn',
	'signUp',
	'confirmSignIn',
	'requireNewPassword',
	'confirmSignUp',
	'forgotPassword',
	'verifyContact',
	'signedIn'
];


class SignIn extends Component {

	state = {
		logedInUser: null,
	}


	componentDidMount = () => {
		const { history, location, match, currentPort } = this.props,
			url_language = match.params.lang;

		auth.getAuthenticatedUser()
			.then(_ => {
				// history.push(`/${url_language}/${currentPort.code}/recaladas`);
				history.push(`/${url_language}/${currentPort.code}/tasks`);
			}).catch(_ => {
				if (authStates.indexOf(location.pathname.substring(1)) >= 0) {
					let path = `${location.pathname.substring(1)}`;
					history.push(`${path}`);
					this.authenticator.handleStateChange(path);
				}
			});
	};


	onStateChange = authState => {
		const { history, location, match, currentPort } = this.props,
			url_language = match.params.lang;

		if (authState !== 'signedIn') {
			history.push(`${authState}`);
		}
		if (authState === 'signedIn') {
			//Si el url estaba en la pantalla de login (signIn), redireccionamos al dashboard
			if (location.pathname === `/${url_language}/signIn` || location.pathname === 'signIn' || location.pathname === '/' || location.pathname === `/${url_language}/requireNewPassword` || location.pathname === `/${url_language}/forgotPassword` || location.pathname === `/${url_language}/confirmSignUp`) {
				// history.push(`/${url_language}/dashboard`);
				// history.push(`/${url_language}/${currentPort.code}/recaladas`);
				history.push(`/${url_language}/${currentPort.code}/tasks`);
			}
		}

	};

	render() {
		const { classes } = this.props;

		return (
			<div className={classes.root}>
				<Grid
					className={classes.grid}
					container
				>
					<Grid
						className={classes.quoteWrapper}
						item
						lg={5}
					>
						<div className={classes.quote}>
							<div className={classes.quoteInner}>

							</div>
						</div>
					</Grid>
					<Grid
						className={classes.content}
						item
						lg={7}
						xs={12}
					>
						<div className={classes.content}>
							<div className={classes.contentHeader}>
								{/* <IconButton
									className={classes.backButton}
									onClick={this.handleBack}
								>
									<ArrowBackIcon />
								</IconButton> */}
							</div>
							<div className={classes.contentBody}>
								<Authenticator
									hideDefault={true}
									onStateChange={this.onStateChange}
									ref={f => { this.authenticator = f; }}>
									<LoginForm {...this.props}
										override={'LoginForm'} />
									<ForgotPassword {...this.props} override={'ForgotPassword'} />
									<RequireNewPassword
										{...this.props} override={'RequireNewPassword'} />
									<ConfirmSignUp {...this.props} />
								</Authenticator>
								<Box style={{
									position: 'fixed',
									bottom: 10,
									right: 10
								}}>
									<FooterLanguageSelector />
								</Box>
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
		)

	}
}

const mapStateToProps = ({ currentPort }) => ({
	currentPort
});

export default connect(mapStateToProps, null)(
	compose(
		withTranslation(),
		withStyles(styles),
	)(SignIn))