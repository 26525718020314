import React, { useState, useEffect } from 'react';

// Externals
import 'moment/locale/es';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { compose } from 'recompose'

// Material helpers
import {
  withStyles,
  withWidth,
  Link
} from '@material-ui/core';
// Material components
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  List,
  ListItem,
  Divider,
} from '@material-ui/core';


import moment from 'moment'

// Shared components
import { Portlet, PortletContent } from 'components';
//Redux
import { connect } from 'react-redux'

import { RecaladaDetailsPopup } from 'components'



// Component styles
import styles from './styles';

function RecaladasList(props) {
  const { classes, from, to, width } = props,
    [recaladas, setRecaladas] = useState([]),
    [selectedRecalada, setSelectedRecalada] = useState(null),
    [openEdit, setOpenEdit] = useState(false),
    { t } = useTranslation();


  useEffect(() => {
    if (props && props.recaladas) {
      setRecaladas(props.recaladas)
    }
  }, [props, props.recaladas])



  const selectRecalada = (recalada) => {
    setSelectedRecalada(recalada)
    setOpenEdit(true)
  }
  const onClose = () => {
    setOpenEdit(false)
    setSelectedRecalada(null)
  }

  return (
    <>

      {(width !== 'xs' && !isMobile) ? (
        <Portlet>
          <PortletContent noPadding>
            <PerfectScrollbar>
              {recaladas.length > 0 ? (
                <Table>
                  <TableHead>
                    <TableRow className={classes.recalada__header}>
                      <TableCell
                        align="left"
                        className={classes.tableCell__header}
                        style={{ paddingLeft: '24px' }}>
                        {t('Name')}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCell__header}>
                        {t('ETA')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recaladas
                      .map((recalada, i) => (
                        <TableRow
                          className={classes.tableRow}
                          hover
                          key={i}
                          onClick={() => selectRecalada(recalada)}
                        >
                          <TableCell className={classes.tableCell}>
                            <div className={classes.tableCellInner}>
                              <Link to="#">
                                <Typography
                                  className={classes.nameText}
                                  variant="body1"
                                >
                                  {recalada.ship ? recalada.ship.name : ''} V-{recalada.itineraryName}
                                </Typography>

                              </Link>
                            </div>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {moment(recalada.ETA).format('dddd DD MMMM HH:mm')}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              ) : (
                  <div className={classes.noRecaladas__container}>
                    <Typography variant="h6">{
                      !from ? t('There are no recaladas') : t('There are no recaladas between {{from}} and {{to}}', { from, to })
                    }</Typography>
                  </div>
                )}
            </PerfectScrollbar>
          </PortletContent>
        </Portlet>
      ) : (
          <>
            {
              recaladas.length > 0 ? (
                <List
                  classes={{ root: classes.list__paper }}>
                  {recaladas
                    .map((recalada, i) => (
                      <React.Fragment key={i}>
                        <ListItem key={i}
                          className={classes.list__item__card}
                        >
                          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography variant="body1" >
                                <strong>{recalada.ship ? recalada.ship.name : ''} V-{recalada.itineraryName}</strong>
                              </Typography>
                              <Typography variant='body1'>{recalada.linerName}</Typography>
                            </div>
                          </div>
                          <div className={classes.list__item__dueDate}>
                            <Typography color='textSecondary' variant='body2'>
                              {moment(recalada.ETA).format('dddd DD MMMM HH:mm')}
                            </Typography>
                          </div>
                        </ListItem>
                        <Divider style={{ width: '100%' }} />
                      </React.Fragment>
                    ))}
                </List>
              ) : (
                  <div className={classes.noRecaladas__container}>
                    <Typography variant="h6">{
                      !from ? t('There are no recaladas') : t('There are no recaladas between {{from}} and {{to}}', { from, to })
                    }</Typography>
                  </div>
                )
            }
          </>
        )
      }
      <RecaladaDetailsPopup open={openEdit}
        onClose={onClose}
        selectedRecalada={selectedRecalada} />
    </>
  );
}

function mapStateToProps(state) {
  return {
    generalParameters: state.generalParameters,
    currentPort: state.currentPort
  }
}

export default compose(
  connect(mapStateToProps, null),
  withStyles(styles),
  withWidth())(RecaladasList);
