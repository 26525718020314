export default theme => ({
    name__container: {
        //cursor: 'pointer',
        marginBottom: theme.spacing(2),
        width: 'fit-content'
    },
    textfield: {
        width: '100%',
        color: theme.palette.text.primary,
        backgroundColor: '#fff',
        padding: theme.spacing()
    },
    actions: {
        marginTop: theme.spacing(),
        display: 'flex'
    },
    actions__save: {
        marginRight: 10
    },
})