import {
    SET_RECALADA,
    SET_RECALADA_DETAILS,
    SET_RECALADA_EMAILS,
    SET_RECALADA_DOCUMENTS,
    SET_RECALADA_TASKS
} from 'actions/recaladaActions'

import {
    ADD_TASK,
    CREATE_TASK,
    DELETE_TASK,
    UPDATE_TASK,
    UPDATE_TASK_KEYWORDS,
    UPDATE_TASK_NAME,
    UPDATE_TASK_DESCRIPTION,
    UPDATE_TASK_DUE_HOURS,
    UPDATE_TASK_COMPLETE,
    UPDATE_TASK_ADD_USER,
    UPDATE_TASK_REMOVE_USER
} from 'actions/tasksActions'


function recalada(state = {}, action) {
    let taskIndex = -1;
    switch (action.type) {
        case SET_RECALADA:
            return {
                ...action.recalada,
                emails: null,
                tasks: null,
                documents: null,
                details: null
            }
        case SET_RECALADA_TASKS:
            return {
                ...state,
                tasks: action.tasks.sort((a, b) => {
                    if (a.dueHours < b.dueHours) return -1;
                    if (a.dueHours > b.dueHours) return 1;
                    if (a.dueHours === b.dueHours) {
                        if (a.name < b.name) return -1;
                        if (a.name > b.name) return 1;
                        return 0
                    };
                    return 0
                })
            }
        case SET_RECALADA_DOCUMENTS:
            return {
                ...state,
                documents: action.documents
            }
        case SET_RECALADA_EMAILS:
            return {
                ...state,
                emails: action.emails
            }
        case SET_RECALADA_DETAILS:
            return {
                ...state,
                details: action.details
            }
        case CREATE_TASK: {
            if (action.data.recaladaId === state.recaladaId) {
                return {
                    ...state,
                    tasks: [
                        ...state.tasks,
                        { ...action.data }
                    ].sort((a, b) => {
                        if (a.dueHours < b.dueHours) return -1;
                        if (a.dueHours > b.dueHours) return 1;
                        if (a.dueHours === b.dueHours) {
                            if (a.name < b.name) return -1;
                            if (a.name > b.name) return 1;
                            return 0
                        };
                        return 0
                    })
                }
            }
            return state;
        }
        case ADD_TASK:
            if (action.task.recaladaId === state.recaladaId) {
                return {
                    ...state,
                    tasks: [
                        ...state.tasks,
                        { ...action.task }
                    ].sort((a, b) => {
                        if (a.dueHours < b.dueHours) return -1;
                        if (a.dueHours > b.dueHours) return 1;
                        if (a.dueHours === b.dueHours) {
                            if (a.name < b.name) return -1;
                            if (a.name > b.name) return 1;
                            return 0
                        };
                        return 0
                    })
                }
            }
            return state;
        case DELETE_TASK:
            if (action.task.recaladaId === state.recaladaId) {
                taskIndex = state.tasks.findIndex(t => t.taskId === action.task.taskId);
                if (taskIndex >= 0) {
                    return {
                        ...state,
                        tasks: [
                            ...state.tasks.slice(0, taskIndex),
                            ...state.tasks.slice(taskIndex + 1),
                        ].sort((a, b) => {
                            if (a.dueHours < b.dueHours) return -1;
                            if (a.dueHours > b.dueHours) return 1;
                            if (a.dueHours === b.dueHours) {
                                if (a.name < b.name) return -1;
                                if (a.name > b.name) return 1;
                                return 0
                            };
                            return 0
                        })
                    }
                }
            }
            return state;
        case UPDATE_TASK:
            if (action.task.recaladaId === state.recaladaId) {
                taskIndex = state.tasks.findIndex(t => t.taskId === action.task.taskId);
                if (taskIndex >= 0) {
                    return {
                        ...state,
                        tasks: [
                            ...state.tasks.slice(0, taskIndex),
                            {
                                ...state.tasks[taskIndex],
                                ...action.task
                            },
                            ...state.tasks.slice(taskIndex + 1),
                        ].sort((a, b) => {
                            if (a.dueHours < b.dueHours) return -1;
                            if (a.dueHours > b.dueHours) return 1;
                            if (a.dueHours === b.dueHours) {
                                if (a.name < b.name) return -1;
                                if (a.name > b.name) return 1;
                                return 0
                            };
                            return 0
                        })
                    }
                }
            }
            return state;
        case UPDATE_TASK_KEYWORDS:
            taskIndex = state.tasks ? state.tasks.findIndex(t => t.taskId === action.data.taskId) : -1;
            if (taskIndex >= 0) {
                return {
                    ...state,
                    tasks: [
                        ...state.tasks.slice(0, taskIndex),
                        {
                            ...state.tasks[taskIndex],
                            keywords: [...action.data.keywords]
                        },
                        ...state.tasks.slice(taskIndex + 1),
                    ]
                }
            }
            return state;

        case UPDATE_TASK_NAME:
            taskIndex = state.tasks ? state.tasks.findIndex(t => t.taskId === action.data.taskId) : -1;
            if (taskIndex >= 0) {
                return {
                    ...state,
                    tasks: [
                        ...state.tasks.slice(0, taskIndex),
                        {
                            ...state.tasks[taskIndex],
                            name: action.data.name
                        },
                        ...state.tasks.slice(taskIndex + 1),
                    ]
                }
            }
            return state;
        case UPDATE_TASK_DESCRIPTION:
            taskIndex = state.tasks ? state.tasks.findIndex(t => t.taskId === action.data.taskId) : -1;
            if (taskIndex >= 0) {
                return {
                    ...state,
                    tasks: [
                        ...state.tasks.slice(0, taskIndex),
                        {
                            ...state.tasks[taskIndex],
                            description: action.data.description
                        },
                        ...state.tasks.slice(taskIndex + 1),
                    ]
                }
            }
            return state;
        case UPDATE_TASK_DUE_HOURS:
            taskIndex = state.tasks ? state.tasks.findIndex(t => t.taskId === action.data.taskId) : -1;
            if (taskIndex >= 0) {
                return {
                    ...state,
                    tasks: [
                        ...state.tasks.slice(0, taskIndex),
                        {
                            ...state.tasks[taskIndex],
                            dueHours: action.data.dueHours
                        },
                        ...state.tasks.slice(taskIndex + 1),
                    ]
                }
            }
            return state;
        case UPDATE_TASK_COMPLETE:
            taskIndex = state.tasks ? state.tasks.findIndex(t => t.taskId === action.data.taskId) : -1;
            if (taskIndex >= 0) {
                return {
                    ...state,
                    tasks: [
                        ...state.tasks.slice(0, taskIndex),
                        {
                            ...state.tasks[taskIndex],
                            complete: action.data.complete
                        },
                        ...state.tasks.slice(taskIndex + 1),
                    ].map(tsk => ({
                        ...tsk,
                        parentTaskComplete: tsk.parentTaskId === action.data.taskId ? action.data.complete : tsk.parentTaskComplete
                    }))
                }
            }
            return state;
        case UPDATE_TASK_ADD_USER:
            taskIndex = state.tasks ? state.tasks.findIndex(t => t.taskId === action.data.taskId) : -1;
            if (taskIndex >= 0) {
                return {
                    ...state,
                    tasks: [
                        ...state.tasks.slice(0, taskIndex),
                        {
                            ...state.tasks[taskIndex],
                            responsiblesUsers: [
                                ...state.tasks[taskIndex].responsiblesUsers,
                                {
                                    id: action.data.id,
                                    firstName: action.data.firstName,
                                    lastName: action.data.lastName,
                                    email: action.data.email,
                                    profileId: action.data.profileId
                                }
                            ]
                        },
                        ...state.tasks.slice(taskIndex + 1),
                    ]
                }
            }
            return state;
        case UPDATE_TASK_REMOVE_USER:
            taskIndex = state.tasks ? state.tasks.findIndex(t => t.taskId === action.data.taskId) : -1;
            if (taskIndex >= 0) {
                return {
                    ...state,
                    tasks: [
                        ...state.tasks.slice(0, taskIndex),
                        {
                            ...state.tasks[taskIndex],
                            responsiblesUsers: state.tasks[taskIndex].responsiblesUsers.filter(r => r.id !== action.data.userId)
                        },
                        ...state.tasks.slice(taskIndex + 1),
                    ]
                }
            }
            return state;
        default:
            return state;
    }
}

export default recalada