import React, { useState } from 'react';

// Externals
import classNames from 'classnames';
import {
	TextField,
	MenuItem,
	Grid
} from '@material-ui/core';

// Material helpers
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

// Shared components
// import { SearchInput } from 'components';

// Component styles
import styles from './styles';

// import CalendarDateSelector from './CalendarDateSelector'

import { UsersFilterSelector } from 'components'

const TasksToolbar = props => {
	const { classes, className, t } = props;
	const rootClassName = classNames(classes.root, className);
	const [selectedType, setSelectedType] = useState(1)

	const taskStatusOptions = [
		{ label: t('All'), value: 0 },
		{ label: t('Pending'), value: 1 },
		{ label: t('Done'), value: 2 },
	]
	const handleChangeType = e => {
		setSelectedType(e.target.value)
		props.handleFilterByStatus && props.handleFilterByStatus(e.target.value);
	}
	return (
		<div className={rootClassName}>
			<Grid container spacing={2} className={classes.row}>
				{/* <Grid item xs={12} sm={4}>
					<SearchInput
						className={classes.searchInput}
						placeholder={t("Search task")}
						onChange={props.handleSearch}
					/>
				</Grid> */}
				<Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
					{/* <CalendarDateSelector {...props} style={{ marginRight: 10 }} /> */}
					<UsersFilterSelector style={{ marginRight: 10 }} />
					<TextField
						InputProps={{
							inputProps: {
								className: classes.select__root
							}
						}}
						select
						labe=''
						value={selectedType}
						onChange={handleChangeType}
						variant='outlined'
					>
						{taskStatusOptions.map(option => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</TextField>
				</Grid>

			</Grid>
		</div >
	);
}

export default compose(withStyles(styles), withTranslation())(TasksToolbar);
