import React, { Component } from 'react';
import {
	CircularProgress,
	Typography,
	withStyles
} from '@material-ui/core';

import { Dashboard as DashboardLayout } from 'layouts';

import { Tasks } from 'services';
import { TasksToolbar, TasksTable } from './components';

import TaskDetails from 'components/TaskDetails';
import VesselInfo from 'components/VesselInfo';

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as tasksActions from 'actions/tasksActions'
import * as recaladaActions from 'actions/recaladaActions'
// import { Recaladas } from 'services'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

// Component styles
import styles from './style';


let timeout = null


class TasksList extends Component {
	signal = true;

	state = {
		isLoading: true,
		limit: 10,
		error: null,
		from: undefined,
		to: undefined,
		search: '',
		fetching: false,
		selectedTask: null,
		selectedRecalada: null,
		showTaskDetails: false,
		filterType: 1,
		showShipDetails: false
	};

	componentDidMount() {
		this.signal = true;
		this.getTasks();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.currentPort && this.props.currentPort && nextProps.currentPort.code !== this.props.currentPort.code) {
			this.setState({
				port: nextProps.currentPort.code
			}, () => {
				this.getTasks();
			})
		}
	}

	componentWillUnmount() {
		this.signal = false;
	}

	getTasks = () => {
		let { search, port, from, to, fetching } = this.state,
			{ currentPort,
				//match,  i18n 
			} = this.props;

		if (fetching) return;

		this.setState({
			isLoading: true,
			fetching: true
		});

		if (!port && currentPort) {
			port = currentPort.code
		}

		Tasks.listTask({
			search,
			portCode: port,
			from,
			to
		})
			.then(response => {
				if (response.success) {
					this.props.tasksActions.setTasks(response.records)
				}
				this.setState({
					isLoading: false,
					fetching: false
				})
			}).catch(err => {
				console.log(err)
				this.setState({
					isLoading: false,
					fetching: false
				})
			})
	}

	handleSelect = selectedTask => {

		this.props.tasksActions.setTask(selectedTask)
		this.props.recaladaActions.setRecalada({
			...selectedTask.recalada,
			ship: selectedTask.ship
		})
		this.setState({
			selectedTask: selectedTask,
			showTaskDetails: true
		})

		// Recaladas.getRecalada({
		// 	recaladaId: selectedTask.recalada.recaladaId
		// })
		// 	.then(response => {
		// 		if (response.success) {
		// 			this.props.recaladaActions.setRecalada(response.record)
		// 		}

		// 	})
	};

	onShipSelect = (selectedTask) => {
		this.setState({
			showShipDetails: true,
			selectedRecalada: selectedTask
		})
	}

	handleCloseTask = () => {
		this.props.recaladaActions.setRecalada(null)
		this.setState({
			selectedTask: null,
			showTaskDetails: false
		})
	}

	handleSearch = (e) => {
		this.setState({
			search: e.target.value
		})

		if (timeout) {
			clearTimeout(timeout);
		}

		timeout = setTimeout(() => {
			this.getTasks()

			clearTimeout(timeout);
			timeout = null;
		}, 700)
	}
	handleChangeFilter = (filter) => {
		this.setState({
			from: filter.from.format('YYYY-MM-DD'),
			to: filter.to.format('YYYY-MM-DD')
		}, () => {
			this.getTasks();
		})
	}
	handleFilterByStatus = (filter) => {
		this.setState({
			filterType: filter
		})
	}

	renderTasks() {
		const { classes, tasks } = this.props;
		const { isLoading, error, filterType } = this.state;

		if (isLoading) {
			return (
				<div className={classes.progressWrapper}>
					<CircularProgress />
				</div>
			);
		}

		if (error) {
			return <Typography variant="h6">{error}</Typography>;
		}

		return (
			<TasksTable
				onSelect={this.handleSelect}
				onShipSelect={this.onShipSelect}
				filterType={filterType}
				tasks={tasks}
			/>
		);
	}

	render() {
		const { classes, t } = this.props;
		const {
			selectedTask,
			showTaskDetails,
			showShipDetails,
			selectedRecalada
		} = this.state;

		return (
			<>
				<DashboardLayout title={t('Tasks')}>
					<div className={classes.root}>
						<TasksToolbar
							handleSearch={this.handleSearch}
							handleChangeFilter={this.handleChangeFilter}
							handleFilterByStatus={this.handleFilterByStatus} />

						<div className={classes.content}>
							{this.renderTasks()}
						</div>
					</div>
				</DashboardLayout>

				<TaskDetails
					selectedTask={selectedTask}
					open={showTaskDetails}
					onClose={this.handleCloseTask} />

				<VesselInfo
					selectedShip={selectedRecalada ? {
						...selectedRecalada.recalada,
						ship: selectedRecalada.ship
					} : null}
					open={showShipDetails}
					onClose={() => {
						this.setState({
							showShipDetails: false,
							selectedRecalada: null
						})
					}}
				/>
			</>
		);
	}
}

const mapStateToProps = ({ tasks, currentPort }) => ({ tasks, currentPort });
const mapDispatchToProps = (dispatch) => ({
	tasksActions: bindActionCreators(tasksActions, dispatch),
	recaladaActions: bindActionCreators(recaladaActions, dispatch)
});


export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles),
	withTranslation()
)(TasksList);
