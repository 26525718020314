import i18n from 'i18next'

import {
    SET_GENERAL_PARAMETERS,
    SET_SELECTED_FILTER,
    CLEAR_SELECTED_FILTER,
    DELETE_TASK_TEMPLATE
} from 'actions/generalActions'

import {
    CREATE_TASK_TEMPLATE,
    UPDATE_TASK_TEMPLATE_NAME,
    UPDATE_TASK_TEMPLATE_REQUIRED_RESULT,
    UPDATE_TASK_TEMPLATE_DESCRIPTION,
    UPDATE_TASK_TEMPLATE_KEYWORDS,
    SET_TASK_TEMPLATE_CHECKLIST,
    UPDATE_TASK_TEMPLATE_ROLE,
    UPDATE_TASK_TEMPLATE_TYPE,
    UPDATE_TASK_TEMPLATE_DUEHOURS,
    UPDATE_TASK_TEMPLATE_PARENT,
    DELETE_TASK_TEMPLATE_ATTACHMENT,
    ADD_TASK_TEMPLATE_ATTACHMENTS,
    CREATE_TASK_TEMPLATE_REMINDER,
    DELETE_TASK_TEMPLATE_REMINDER,
    UPDATE_TASK_TEMPLATE_REMINDER
} from 'actions/taskTemplateActions'

import {
    CREAETE_SERVICE_TEMPLATE,
    UPDATE_SERVICE_TEMPLATE_NAME
} from 'actions/serviceTemplateActions'

import {
    CREATE_RECALADA,
    UPDATE_RECALADA
} from 'actions/recaladaActions'

import {
    UPDATE_SERVICE,
    CREATE_SERVICE
} from 'actions/serviceActions'

export default function account(state = {}, action) {
    let taskTemplateId = -1,
        serviceTemplateId = -1,
        serviceIndex = -1,
        itineraryIndex = -1;
    switch (action.type) {
        case SET_GENERAL_PARAMETERS:
            return {
                ...action.parameters,
                beforeAfterOptions: [
                    {
                        value: 0,
                        label: i18n.t('Before')
                    },
                    {
                        value: 1,
                        label: i18n.t('After')
                    }
                ],
                hoursOrDays: [
                    {
                        value: 0,
                        label: i18n.t('Horas')
                    },
                    {
                        value: 1,
                        label: i18n.t('Días')
                    }
                ]
            };
        case SET_SELECTED_FILTER:
            return {
                ...state,
                selectedFilter: action.filter
            }
        case CLEAR_SELECTED_FILTER:
            return {
                ...state,
                selectedFilter: null
            }
        case CREATE_RECALADA:
            return {
                ...state,
                itineraries: [
                    { ...action.recalada },
                    ...state.itineraries,
                ]
            };
        case UPDATE_RECALADA:
            itineraryIndex = state.itineraries.findIndex(i => i.id === action.recalada.itineraryId);
            if (itineraryIndex >= 0) {
                return {
                    ...state,
                    itineraries: [
                        ...state.itineraries.slice(0, itineraryIndex),
                        {
                            ...state.itineraries[itineraryIndex],
                            ...action.recalada,
                        },
                        ...state.itineraries.slice(itineraryIndex + 1)
                    ]
                };
            }
            return state;
        case CREATE_TASK_TEMPLATE:
            serviceTemplateId = state.servicesTemplates.findIndex(st => st.serviceTemplateId === action.data.serviceTemplateId);
            if (serviceTemplateId >= 0) {
                return {
                    ...state,
                    servicesTemplates: [
                        ...state.servicesTemplates.slice(0, serviceTemplateId),
                        {
                            ...state.servicesTemplates[serviceTemplateId],
                            taskTemplates: [
                                ...state.servicesTemplates[serviceTemplateId].taskTemplates,
                                {
                                    ...action.data
                                }
                            ].sort((a, b) => {
                                if (a.dueHours < b.dueHours) return -1;
                                if (a.dueHours > b.dueHours) return 1;
                                if (a.dueHours === b.dueHours) {
                                    if (a.name < b.name) return -1;
                                    if (a.name > b.name) return 1;
                                    return 0
                                };
                                return 0
                            })
                        },
                        ...state.servicesTemplates.slice(serviceTemplateId + 1),
                    ]
                }
            }
            return state;
        case DELETE_TASK_TEMPLATE:
            serviceTemplateId = state.servicesTemplates.findIndex(st => st.serviceTemplateId === action.serviceTemplateId)

            if (serviceTemplateId >= 0) {
                let taskTemplates = state.servicesTemplates[serviceTemplateId].taskTemplates.filter(tt => tt.id !== action.taskTemplateId)

                return {
                    ...state,
                    servicesTemplates: [
                        ...state.servicesTemplates.slice(0, serviceTemplateId),
                        {
                            ...state.servicesTemplates[serviceTemplateId],
                            taskTemplates: taskTemplates
                        },
                        ...state.servicesTemplates.slice(serviceTemplateId + 1),
                    ]
                }
            }
            return state;
        case UPDATE_TASK_TEMPLATE_NAME:
            serviceTemplateId = state.servicesTemplates.findIndex(st => st.serviceTemplateId === action.data.serviceTemplateId);
            if (serviceTemplateId >= 0) {
                taskTemplateId = state.servicesTemplates[serviceTemplateId].taskTemplates.findIndex(tt => tt.id === action.data.taskTemplateId)
                if (taskTemplateId >= 0) {
                    return {
                        ...state,
                        servicesTemplates: [
                            ...state.servicesTemplates.slice(0, serviceTemplateId),
                            {
                                ...state.servicesTemplates[serviceTemplateId],
                                taskTemplates: [
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(0, taskTemplateId),
                                    {
                                        ...state.servicesTemplates[serviceTemplateId].taskTemplates[taskTemplateId],
                                        name: action.data.name
                                    },
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(taskTemplateId + 1)
                                ].sort((a, b) => {
                                    if (a.dueHours < b.dueHours) return -1;
                                    if (a.dueHours > b.dueHours) return 1;
                                    if (a.dueHours === b.dueHours) {
                                        if (a.name < b.name) return -1;
                                        if (a.name > b.name) return 1;
                                        return 0
                                    };
                                    return 0
                                })
                            },
                            ...state.servicesTemplates.slice(serviceTemplateId + 1),
                        ]
                    }
                }
            }
            return state;
        case UPDATE_TASK_TEMPLATE_DESCRIPTION:
            serviceTemplateId = state.servicesTemplates.findIndex(st => st.serviceTemplateId === action.data.serviceTemplateId);
            if (serviceTemplateId >= 0) {
                taskTemplateId = state.servicesTemplates[serviceTemplateId].taskTemplates.findIndex(tt => tt.id === action.data.taskTemplateId)
                if (taskTemplateId >= 0) {
                    return {
                        ...state,
                        servicesTemplates: [
                            ...state.servicesTemplates.slice(0, serviceTemplateId),
                            {
                                ...state.servicesTemplates[serviceTemplateId],
                                taskTemplates: [
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(0, taskTemplateId),
                                    {
                                        ...state.servicesTemplates[serviceTemplateId].taskTemplates[taskTemplateId],
                                        description: action.data.description
                                    },
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(taskTemplateId + 1)
                                ]
                            },
                            ...state.servicesTemplates.slice(serviceTemplateId + 1),
                        ]
                    }
                }
            }
            return state;
        case UPDATE_TASK_TEMPLATE_REQUIRED_RESULT:
            serviceTemplateId = state.servicesTemplates.findIndex(st => st.serviceTemplateId === action.data.serviceTemplateId);
            if (serviceTemplateId >= 0) {
                taskTemplateId = state.servicesTemplates[serviceTemplateId].taskTemplates.findIndex(tt => tt.id === parseInt(action.data.taskTemplateId))
                if (taskTemplateId >= 0) {
                    return {
                        ...state,
                        servicesTemplates: [
                            ...state.servicesTemplates.slice(0, serviceTemplateId),
                            {
                                ...state.servicesTemplates[serviceTemplateId],
                                taskTemplates: [
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(0, taskTemplateId),
                                    {
                                        ...state.servicesTemplates[serviceTemplateId].taskTemplates[taskTemplateId],
                                        requiredResult: action.data.requiredResult
                                    },
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(taskTemplateId + 1)
                                ]
                            },
                            ...state.servicesTemplates.slice(serviceTemplateId + 1),
                        ]
                    }
                }
            }
            return state;
        case UPDATE_TASK_TEMPLATE_KEYWORDS:
            serviceTemplateId = state.servicesTemplates.findIndex(st => st.serviceTemplateId === action.data.serviceTemplateId);
            if (serviceTemplateId >= 0) {
                taskTemplateId = state.servicesTemplates[serviceTemplateId].taskTemplates.findIndex(tt => tt.id === action.data.taskTemplateId)
                if (taskTemplateId >= 0) {
                    return {
                        ...state,
                        servicesTemplates: [
                            ...state.servicesTemplates.slice(0, serviceTemplateId),
                            {
                                ...state.servicesTemplates[serviceTemplateId],
                                taskTemplates: [
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(0, taskTemplateId),
                                    {
                                        ...state.servicesTemplates[serviceTemplateId].taskTemplates[taskTemplateId],
                                        keywords: [...action.data.keywords]
                                    },
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(taskTemplateId + 1)
                                ]
                            },
                            ...state.servicesTemplates.slice(serviceTemplateId + 1),
                        ]
                    }
                }
            }
            return state;
        case SET_TASK_TEMPLATE_CHECKLIST:
            serviceTemplateId = state.servicesTemplates.findIndex(st => st.serviceTemplateId === action.data.serviceTemplateId);
            if (serviceTemplateId >= 0) {
                taskTemplateId = state.servicesTemplates[serviceTemplateId].taskTemplates.findIndex(tt => tt.id === action.data.taskTemplateId)
                if (taskTemplateId >= 0) {
                    return {
                        ...state,
                        servicesTemplates: [
                            ...state.servicesTemplates.slice(0, serviceTemplateId),
                            {
                                ...state.servicesTemplates[serviceTemplateId],
                                taskTemplates: [
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(0, taskTemplateId),
                                    {
                                        ...state.servicesTemplates[serviceTemplateId].taskTemplates[taskTemplateId],
                                        checklist: [...action.data.checklist]
                                    },
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(taskTemplateId + 1)
                                ]
                            },
                            ...state.servicesTemplates.slice(serviceTemplateId + 1),
                        ]
                    }
                }
            }
            return state;
        case UPDATE_TASK_TEMPLATE_ROLE:
            serviceTemplateId = state.servicesTemplates.findIndex(st => st.serviceTemplateId === action.data.serviceTemplateId);
            if (serviceTemplateId >= 0) {
                taskTemplateId = state.servicesTemplates[serviceTemplateId].taskTemplates.findIndex(tt => tt.id === action.data.taskTemplateId)
                if (taskTemplateId >= 0) {
                    return {
                        ...state,
                        servicesTemplates: [
                            ...state.servicesTemplates.slice(0, serviceTemplateId),
                            {
                                ...state.servicesTemplates[serviceTemplateId],
                                taskTemplates: [
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(0, taskTemplateId),
                                    {
                                        ...state.servicesTemplates[serviceTemplateId].taskTemplates[taskTemplateId],
                                        roleId: action.data.roleId,
                                        roleName: action.data.roleName,
                                    },
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(taskTemplateId + 1)
                                ]
                            },
                            ...state.servicesTemplates.slice(serviceTemplateId + 1),
                        ]
                    }
                }
            }
            return state;
        case UPDATE_TASK_TEMPLATE_TYPE:
            serviceTemplateId = state.servicesTemplates.findIndex(st => st.serviceTemplateId === action.data.serviceTemplateId);
            if (serviceTemplateId >= 0) {
                taskTemplateId = state.servicesTemplates[serviceTemplateId].taskTemplates.findIndex(tt => tt.id === action.data.taskTemplateId)
                if (taskTemplateId >= 0) {
                    return {
                        ...state,
                        servicesTemplates: [
                            ...state.servicesTemplates.slice(0, serviceTemplateId),
                            {
                                ...state.servicesTemplates[serviceTemplateId],
                                taskTemplates: [
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(0, taskTemplateId),
                                    {
                                        ...state.servicesTemplates[serviceTemplateId].taskTemplates[taskTemplateId],
                                        taskTypeId: action.data.taskTypeId,
                                        roleId: action.data.roleId,
                                        roleName: action.data.roleName
                                    },
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(taskTemplateId + 1)
                                ]
                            },
                            ...state.servicesTemplates.slice(serviceTemplateId + 1),
                        ]
                    }
                }
            }
            return state;
        case UPDATE_TASK_TEMPLATE_PARENT:
            serviceTemplateId = state.servicesTemplates.findIndex(st => st.serviceTemplateId === action.data.serviceTemplateId);
            if (serviceTemplateId >= 0) {
                taskTemplateId = state.servicesTemplates[serviceTemplateId].taskTemplates.findIndex(tt => tt.id === action.data.taskTemplateId)
                if (taskTemplateId >= 0) {
                    return {
                        ...state,
                        servicesTemplates: [
                            ...state.servicesTemplates.slice(0, serviceTemplateId),
                            {
                                ...state.servicesTemplates[serviceTemplateId],
                                taskTemplates: [
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(0, taskTemplateId),
                                    {
                                        ...state.servicesTemplates[serviceTemplateId].taskTemplates[taskTemplateId],
                                        parentTaskTemplateId: action.data.parentTaskTemplateId,
                                        parentTaskTemplateName: action.data.parentTaskTemplateName,
                                        parentTaskTemplateDescription: action.data.parentTaskTemplateDescription
                                    },
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(taskTemplateId + 1)
                                ]
                            },
                            ...state.servicesTemplates.slice(serviceTemplateId + 1),
                        ]
                    }
                }
            }
            return state;
        case UPDATE_TASK_TEMPLATE_DUEHOURS:
            serviceTemplateId = state.servicesTemplates.findIndex(st => st.serviceTemplateId === action.data.serviceTemplateId);
            if (serviceTemplateId >= 0) {
                taskTemplateId = state.servicesTemplates[serviceTemplateId].taskTemplates.findIndex(tt => tt.id === action.data.taskTemplateId)
                if (taskTemplateId >= 0) {
                    return {
                        ...state,
                        servicesTemplates: [
                            ...state.servicesTemplates.slice(0, serviceTemplateId),
                            {
                                ...state.servicesTemplates[serviceTemplateId],
                                taskTemplates: [
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(0, taskTemplateId),
                                    {
                                        ...state.servicesTemplates[serviceTemplateId].taskTemplates[taskTemplateId],
                                        dueHours: action.data.dueHours,
                                        dueDateReferenceId: action.data.dueDateReferenceId
                                    },
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(taskTemplateId + 1)
                                ]
                                // .sort((a, b) => {
                                //     if (a.dueHours < b.dueHours) return -1;
                                //     if (a.dueHours > b.dueHours) return 1;
                                //     if (a.dueHours === b.dueHours) {
                                //         if (a.name < b.name) return -1;
                                //         if (a.name > b.name) return 1;
                                //         return 0
                                //     };
                                //     return 0
                                // })
                            },
                            ...state.servicesTemplates.slice(serviceTemplateId + 1),
                        ]
                    }
                }
            }
            return state;

        case DELETE_TASK_TEMPLATE_ATTACHMENT: {
            serviceTemplateId = state.servicesTemplates.findIndex(st => st.serviceTemplateId === action.data.serviceTemplateId);
            if (serviceTemplateId >= 0) {
                taskTemplateId = state.servicesTemplates[serviceTemplateId].taskTemplates.findIndex(tt => tt.id === action.data.taskTemplateId)
                if (taskTemplateId >= 0) {
                    return {
                        ...state,
                        servicesTemplates: [
                            ...state.servicesTemplates.slice(0, serviceTemplateId),
                            {
                                ...state.servicesTemplates[serviceTemplateId],
                                taskTemplates: [
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(0, taskTemplateId),
                                    {
                                        ...state.servicesTemplates[serviceTemplateId].taskTemplates[taskTemplateId],
                                        attachments: state.servicesTemplates[serviceTemplateId].taskTemplates[taskTemplateId].attachments.filter(a => a.taskTemplateAttachmentId !== action.data.taskTemplateAttachmentId)
                                    },
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(taskTemplateId + 1)
                                ]
                            },
                            ...state.servicesTemplates.slice(serviceTemplateId + 1),
                        ]
                    }
                }
            }
            return state;
        }
        case ADD_TASK_TEMPLATE_ATTACHMENTS: {
            serviceTemplateId = state.servicesTemplates.findIndex(st => st.serviceTemplateId === action.data.serviceTemplateId);
            if (serviceTemplateId >= 0) {
                taskTemplateId = state.servicesTemplates[serviceTemplateId].taskTemplates.findIndex(tt => tt.id === action.data.taskTemplateId)
                if (taskTemplateId >= 0) {
                    return {
                        ...state,
                        servicesTemplates: [
                            ...state.servicesTemplates.slice(0, serviceTemplateId),
                            {
                                ...state.servicesTemplates[serviceTemplateId],
                                taskTemplates: [
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(0, taskTemplateId),
                                    {
                                        ...state.servicesTemplates[serviceTemplateId].taskTemplates[taskTemplateId],
                                        attachments: [
                                            ...state.servicesTemplates[serviceTemplateId].taskTemplates[taskTemplateId].attachments,
                                            ...action.data.attachments
                                        ]
                                    },
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(taskTemplateId + 1)
                                ]
                            },
                            ...state.servicesTemplates.slice(serviceTemplateId + 1),
                        ]
                    }
                }
            }
            return state;
        }

        case CREATE_TASK_TEMPLATE_REMINDER: {
            serviceTemplateId = state.servicesTemplates.findIndex(st => st.serviceTemplateId === action.reminder.serviceTemplateId);
            if (serviceTemplateId >= 0) {
                taskTemplateId = state.servicesTemplates[serviceTemplateId].taskTemplates.findIndex(tt => tt.id === action.reminder.taskTemplateId)
                if (taskTemplateId >= 0) {
                    return {
                        ...state,
                        servicesTemplates: [
                            ...state.servicesTemplates.slice(0, serviceTemplateId),
                            {
                                ...state.servicesTemplates[serviceTemplateId],
                                taskTemplates: [
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(0, taskTemplateId),
                                    {
                                        ...state.servicesTemplates[serviceTemplateId].taskTemplates[taskTemplateId],
                                        reminders: state.servicesTemplates[serviceTemplateId].taskTemplates[taskTemplateId].reminders ? [
                                            ...state.servicesTemplates[serviceTemplateId].taskTemplates[taskTemplateId].reminders,
                                            { ...action.reminder }
                                        ] : [
                                                { ...action.reminder }
                                            ]

                                    },
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(taskTemplateId + 1)
                                ]
                            },
                            ...state.servicesTemplates.slice(serviceTemplateId + 1),
                        ]
                    }
                }
            }
            return state;
        }
        case DELETE_TASK_TEMPLATE_REMINDER: {
            serviceTemplateId = state.servicesTemplates.findIndex(st => st.serviceTemplateId === action.reminder.serviceTemplateId);
            if (serviceTemplateId >= 0) {
                taskTemplateId = state.servicesTemplates[serviceTemplateId].taskTemplates.findIndex(tt => tt.id === action.reminder.taskTemplateId)
                if (taskTemplateId >= 0) {
                    return {
                        ...state,
                        servicesTemplates: [
                            ...state.servicesTemplates.slice(0, serviceTemplateId),
                            {
                                ...state.servicesTemplates[serviceTemplateId],
                                taskTemplates: [
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(0, taskTemplateId),
                                    {
                                        ...state.servicesTemplates[serviceTemplateId].taskTemplates[taskTemplateId],
                                        reminders: state.servicesTemplates[serviceTemplateId].taskTemplates[taskTemplateId].reminders.filter(r => r.taskTemplateReminderId !== action.reminder.taskTemplateReminderId)
                                    },
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(taskTemplateId + 1)
                                ]
                            },
                            ...state.servicesTemplates.slice(serviceTemplateId + 1),
                        ]
                    }
                }
            }
            return state;
        }
        case UPDATE_TASK_TEMPLATE_REMINDER: {
            serviceTemplateId = state.servicesTemplates.findIndex(st => st.serviceTemplateId === action.reminder.serviceTemplateId);
            if (serviceTemplateId >= 0) {
                taskTemplateId = state.servicesTemplates[serviceTemplateId].taskTemplates.findIndex(tt => tt.id === action.reminder.taskTemplateId)
                if (taskTemplateId >= 0) {

                    let reminderIndex = state.servicesTemplates[serviceTemplateId].taskTemplates[taskTemplateId].reminders.findIndex(r => r.id === action.reminder.id)
                    return {
                        ...state,
                        servicesTemplates: [
                            ...state.servicesTemplates.slice(0, serviceTemplateId),
                            {
                                ...state.servicesTemplates[serviceTemplateId],
                                taskTemplates: [
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(0, taskTemplateId),
                                    {
                                        ...state.servicesTemplates[serviceTemplateId].taskTemplates[taskTemplateId],
                                        reminders: [
                                            ...state.servicesTemplates[serviceTemplateId].taskTemplates[taskTemplateId].reminders.slice(0, reminderIndex),
                                            { ...action.reminder },
                                            ...state.servicesTemplates[serviceTemplateId].taskTemplates[taskTemplateId].reminders.slice(reminderIndex + 1),
                                        ]
                                    },
                                    ...state.servicesTemplates[serviceTemplateId].taskTemplates.slice(taskTemplateId + 1)
                                ]
                            },
                            ...state.servicesTemplates.slice(serviceTemplateId + 1),
                        ]
                    }
                }
            }
            return state;
        }
        case UPDATE_SERVICE_TEMPLATE_NAME:
            serviceTemplateId = state.servicesTemplates.findIndex(st => st.serviceTemplateId === action.data.serviceTemplateId);
            if (serviceTemplateId >= 0) {
                return {
                    ...state,
                    servicesTemplates: [
                        ...state.servicesTemplates.slice(0, serviceTemplateId),
                        {
                            ...state.servicesTemplates[serviceTemplateId],
                            name: action.data.name
                        },
                        ...state.servicesTemplates.slice(serviceTemplateId + 1),
                    ]
                }
            }
            return state;
        case CREAETE_SERVICE_TEMPLATE:
            return {
                ...state,
                servicesTemplates: [
                    ...state.servicesTemplates,
                    { ...action.data },
                ]
            }

        case UPDATE_SERVICE:
            serviceIndex = state.services.findIndex(st => st.id === action.data.id);

            if (serviceIndex >= 0) {
                return {
                    ...state,
                    services: [
                        ...state.services.slice(0, serviceIndex),
                        {
                            ...state.services[serviceIndex],
                            ...action.data
                        },
                        ...state.services.slice(serviceIndex + 1),
                    ]
                }
            }
            return state;
        case CREATE_SERVICE:
            let newServices = [...state.services]
            newServices.push(action.data)

            return {
                ...state,
                services: newServices
            }


        default:
            return state;
    }
}