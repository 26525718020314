export default theme => ({
  root: {},
  header__row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  header__mobile__row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '24px',
    paddingLeft: '16px'
  },
  bottomNavigation__container: {
    position: 'fixed',
    zIndex: 100000,
    bottom: 0,
    left: 0,
    right: 0,
    marginTop: 50
  },
  row: {
    display: 'flex',
    marginTop: '24px',
    flexDirection: 'column'
  },
  paper: {
    flexGrow: 1
  },
  selected__tab: {
    border: '1px solid'
  },
  ship__tabs: {
    borderBottom: `1px solid ${theme.palette.primary.main}`
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  shipDetail__toolBar: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  shipDetail__toolBar__element: {
    flexDirection: 'column'
  },
  shipDetails__content__progress: {
    textAlign: 'center',
    position: 'absolute',
    marginTop: '24px',
    width: '100%'
  },
  nested: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderBottom: '1px solid #e1e4ea',
    // marginBottom: theme.spacing(1),
    padding: 0
  },
  list__item__card: {
    padding: 10,
    width: '100%',
    position: 'relative'
  },
  tableRow: {
    height: '34px',
    cursor: 'pointer'
  },
  'taskRow--completed': {
    backgroundColor: '#f8fafc',
  },
  tableCell: {
    whiteSpace: 'nowrap',
    padding: '10px'
  },
  tableCell__header: {
    whiteSpace: 'nowrap',
    padding: '10px',
    color: '#fff'
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  'mail--read': {
    backgroundColor: 'rgba(239,239,239,0.3)'
  },
  'mail--unread': {
    backgroundColor: 'unset'
  },
  warningIcon: {
    color: 'red'
  },
  successIcon: {
    color: 'rgba(0,128,0,0.7)'
  },
  recalada__header: {
    background: theme.palette.primary.main,
  },
  list: {
    backgroundColor: '#fff',
    // padding: 8,
    marginBottom: theme.spacing(5)
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
  },
  card: {
    cursor: 'pointer',
    minHeight: '150px'
  },
  docs__icons: {
    color: theme.palette.text.secondary
  },
  tasks__list__root: {
    backgroundColor: '#fff',
    borderTop: '1px solid #e1e4ea',
    padding: '0 0 60px 0'
  },
  //Details
  details__container: {
    padding: '16px 8px'
  },
  details__fields__container: {
    display: 'flex',
    margin: '10px 0',
    flexDirection: 'column',
    border: '1px solid #e1e4ea',
    padding: '16px 8px',
    position: 'relative'
  },
  details__textfields__container: {
    display: 'flex', marginBottom: '24px', cursor: 'pointer', marginLeft: 5, marginRight: 5
  },
  details__texfield__edit: {
    display: 'flex', justifyContent: 'center', alignItems: 'center',
  },
  tasks__header__container: {
    padding: '8px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      height: 140,
      padding: '16px 8px',
      flexWrap: 'wrap'
    }
  },
  tasks__header__filters__container: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  },
  textField__input: {
    padding: 10,
    fontSize: '12px'
  },
  textField_addornment__input: {
    padding: '10px 10px 10px 0'
  },
  datePicker__input__root: {
    width: '100%'
  },
  title__content: {
    display: 'flex',
    flexDirection: 'column'
  }
});
