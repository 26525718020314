import React, { useState, useEffect, useCallback } from 'react';

// Externals
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

// Material helpers
import { makeStyles, CircularProgress } from '@material-ui/core';
import { isMobile } from 'react-device-detect'

//Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as recaladasActions from 'actions/recaladasActions'

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import List from './List';
import { ToolBar } from './components'

import { Recaladas as RecaladasService } from 'services'

// Component styles
const styles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1) * 4
  },
  mobile_root: {
    padding: '16px 0'
  }
}));


function Recaladas(props) {
  const { currentPort, match } = props;
  const [selectedRecalada, setSelectedRecalada] = useState(null),
    [fetching, setFetching] = useState(true),
    [recaladas, setRecaladas] = useState(props.recaladas || []),
    [from, setFrom] = useState(undefined),
    [to, setTo] = useState(undefined),
    { t, i18n } = useTranslation(),
    classes = styles();

  const getRecaladas = useCallback(() => {
    if (currentPort) {
      setFetching(true)
      RecaladasService.getRecaladas({
        port: currentPort.code,
        from,
        to
      }).then(response => {
        if (response.success) {
          props.recaladasActions.setRecaladas(response.data.records.recaladas)
        }
      })
    }
  }, [currentPort, from, to, props.recaladasActions]);

  useEffect(() => {
    getRecaladas()
  }, [from, to, currentPort, getRecaladas])

  useEffect(() => {
    setFetching(false)
  }, [recaladas])




  useEffect(() => {
    let isSuscribed = true;
    if (isSuscribed) {
      setRecaladas(props.recaladas)
    }
    return () => {
      isSuscribed = false
    };
  }, [props.recaladas])


  const handleChangeFilter = (filter) => {
    setFrom(filter.from.format('YYYY-MM-DD'))
    setTo(filter.to.format('YYYY-MM-DD'))
  }

  const handleSelectedRecalada = (recalada) => {
    props.history.push(`/${i18n.language}/${currentPort.code}/settings/recaladas/${recalada.serviceId}`)
    setSelectedRecalada(recalada)
  }

  return (
    <DashboardLayout
      title={selectedRecalada ? selectedRecalada.name : t('Recaladas')}
      showBackMenuButton={selectedRecalada ? true : false}
      goBackUrl={`/${i18n.language}/${currentPort ? currentPort.code : match.params.port}/settings/recaladas`}
    >
      <div className={isMobile ? classes.mobile_root : classes.root}>
        {fetching ? (
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 300
          }} >
            <CircularProgress />
          </div>
        ) : (
            <>
              <div style={{ width: '100%', marginBottom: '15px' }}>
                <ToolBar
                  handleChangeFilter={handleChangeFilter} />
              </div>
              <List
                recaladas={recaladas}
                from={from}
                to={to}
                setSelectedRecalada={handleSelectedRecalada}
              />
            </>
          )}
      </div>
    </DashboardLayout>
  );
}

function mapStateToProps(state) {
  return {
    generalParameters: state.generalParameters,
    currentPort: state.currentPort,
    recaladas: state.recaladas
  }
}
function mapDispatchToProps(dispatch) {
  return {
    recaladasActions: bindActionCreators(recaladasActions, dispatch),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Recaladas));
