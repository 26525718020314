export default theme => ({
    root: {

    },
    description__container: {
        padding: theme.spacing(2),
        borderRadius: 5,
        backgroundColor: '#f4f4f4',
        margin: `${theme.spacing(2)}px 0`,
        //cursor: 'pointer'
    },
    actions: {
        marginTop: theme.spacing(),
        display: 'flex'
    },
    description__textfield: {
        width: '100%',
        backgroundColor: '#fff',
        padding: theme.spacing()
    },
    actions__save: {
        marginRight: 10
    },
})
