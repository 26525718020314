import React, { Component } from 'react';

// Externals
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom';

import {
    withStyles,
    Typography,
} from '@material-ui/core';
import TaskDetails from 'components/TaskDetails';
import { CreateTask } from 'components'

// Component styles
import styles from './styles';


//Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as recaladaActions from 'actions/recaladaActions'
import * as taskActions from 'actions/tasksActions'
import RecaladaTaskTypeFilter from 'components/RecaladaTaskTypeFilter';
import { TasksTable } from 'views/Tasks/components';
import { UsersFilterSelector } from 'components'


class ShipDetails extends Component {

    state = {
        tasks: this.props.recalada.tasks,
        originalTasks: this.props.recalada.tasks,
        selectedTask: null,
        showTaskDetails: false,
        filterType: null
    }

    componentWillReceiveProps = (nextProps) => {
        const { filterType } = this.state;

        let newTasks = [...nextProps.recalada.tasks];
        if (filterType) {
            newTasks = nextProps.recalada.tasks.filter(task => filterType === 5 ? !task.taskTypeId : task.taskTypeId === filterType)
        }


        this.setState({
            tasks: newTasks,
            originalTasks: this.props.recalada.tasks
        })
    }

    handleSelectTask = (task) => {
        taskActions.setTask(task)
        this.setState({
            selectedTask: task,
            showTaskDetails: true
        })
    }
    handleCloseTask = () => {
        this.setState({
            selectedTask: null,
            showTaskDetails: false
        })
    }

    handleTypeFilter = (type) => {
        const { originalTasks } = this.state;

        let newTasks = [...originalTasks];

        if (type) {
            newTasks = newTasks.filter(task => type === 5 ? !task.taskTypeId : task.taskTypeId === type)
        }

        this.setState({
            tasks: newTasks,
            filterType: type
        })
    }

    render() {
        const { t, recalada, classes } = this.props,
            { tasks,
                originalTasks,
                selectedTask,
                showTaskDetails
            } = this.state;


        return (
            <>
                {originalTasks && originalTasks.length > 0 &&
                    <div className={classes.tasks__header__container}>
                        <div className={classes.tasks__header__filters__container}>
                            <div style={{ marginRight: 10, display: 'flex', alignItems: 'center' }}>
                                <UsersFilterSelector />
                            </div>
                            <RecaladaTaskTypeFilter
                                onChange={this.handleTypeFilter}
                            />
                        </div>
                        <CreateTask recalada={recalada} />
                    </div>
                }
                {tasks && tasks.length > 0 ?
                    <TasksTable
                        tasks={tasks}
                        ship={true}
                        recalada={recalada}
                        ETA={recalada ? recalada.ETA : null}
                        onSelect={this.handleSelectTask}
                    />
                    :
                    <div style={{ width: '100%', margin: '24px auto' }}>
                        <Typography>
                            {t('No tasks')}
                        </Typography>
                    </div>
                }
                <TaskDetails
                    selectedTask={selectedTask}
                    open={showTaskDetails}
                    onClose={this.handleCloseTask} />
            </>);
    }
}


function mapDispatchToProps(dispatch) {
    return {
        recaladaActions: bindActionCreators(recaladaActions, dispatch),
        taskActions: bindActionCreators(taskActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        recalada: state.recalada,
        usersFilter: state.usersFilter
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(withStyles(styles)(ShipDetails))));
