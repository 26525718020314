export default theme => ({
  root: {
    padding: theme.spacing(1) * 4
  },
  mobile_root: {
    padding: '16px 0'
  },
  tableRow: {
    height: '34px',
    cursor: 'pointer'
  },
  tableCell: {
    whiteSpace: 'nowrap',
    padding: '10px'
  },
  tableCell__header: {
    whiteSpace: 'nowrap',
    padding: '10px',
    color: '#fff'
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px'
  },
  nameText: {
    display: 'inline-block',
    marginLeft: theme.spacing(2),
    fontWeight: 500,
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0
    }
  },
  typeText: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0
    }
  },
  unreadEmailsText: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0
    }
  },
  nested: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #c4c4c4',
    marginBottom: theme.spacing(1),
    padding: 0
  },
  recalada__header: {
    background: theme.palette.primary.main,
  },
  'mail--read': {
    backgroundColor: 'rgba(239,239,239,0.3)'
  },
  'mail--unread': {
    backgroundColor: 'unset'
  },
  warningIcon: {
    color: 'red'
  },
  successIcon: {
    color: 'green'
  },
  list__paper: {
    width: '100%',
    backgroundColor: '#fff',
    padding: 0
  },
  list__item__card: {
    padding: 10,
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  'list__item__card--warning': {
    backgroundColor: 'rgba(255, 0, 0, 0.1)'
  },
  'list__item__card--success': {
    // backgroundColor: 'rgba(0, 255, 0, 0.1)'
  },
  list__item__card__icon__container: {
    // position: 'absolute',
    // right: theme.spacing(1),
    // top: theme.spacing(1)
  },
  list__item__dueDate: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(1)
  },
  tasks__container: {
    marginBottom: theme.spacing(),
  },
  tasks__indicator__container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(),
  },
  header__container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px',
    [theme.breakpoints.down('xs')]: {
      padding: 10
    }
  },
  title__edit__container: {
    display: 'flex',
    alignItems: 'center'
  },
  textfield: {
    width: '100%',
    color: theme.palette.text.primary,
    backgroundColor: '#fff',
    padding: theme.spacing()
  },
  actions: {
    marginLeft: theme.spacing(),
    display: 'flex',
    alignItems: 'center'
  },
  actions__save: {
    marginRight: 10
  },
  noTaskTemplates__container: {
    width: '100%',
    background: '#fff',
    height: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
