import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom';

import {
    withStyles,
    Typography,
    Tabs,
    Tab,
    Paper,
    Hidden,
    Divider,
    BottomNavigation,
    BottomNavigationAction,
    CircularProgress,
    Badge
} from '@material-ui/core';
import {
    DirectionsBoatOutlined,
    AssignmentOutlined,
    DescriptionOutlined,
    MailOutline,
} from '@material-ui/icons'
import moment from "moment"

// Component styles
import styles from './styles';
import { Recaladas } from 'services';
import EmailsTable from './EmailsTable'
import TasksTable from './TasksTable'
import Details from './Details'
import DocumentsTable from './DocumentsTable'


//Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as recaladasActions from 'actions/recaladasActions'
import * as recaladaActions from 'actions/recaladaActions'

const tabsOptions = ["Tasks", "Documents", "Emails", "Details"]

class ShipDetails extends Component {

    state = {
        currentTab: 0,
        fetching: false,
    }

    componentDidMount = () => {
        if (this.props.recalada && !this.props.recalada.tasks) {
            this.getRecaladaTasks()
        }
    }
    componentWillReceiveProps = () => {
        if (this.props.recalada && !this.props.recalada.tasks) {
            this.getRecaladaTasks()
        }
    }


    handleChangeTab = (e, tab) => {
        const { recalada } = this.props;

        switch (tab) {
            case 0:
                if (!recalada.tasks) {
                    this.getRecaladaTasks()
                }
                break;
            case 1:
                if (!recalada.documents) {
                    this.getRecaladaDocuments()
                }
                break;
            case 2:
                if (!recalada.emails) {
                    this.getRecaladaEmails()
                }
                break;
            case 3:
                if (!recalada.details) {
                    // this.getRecaladaDetails()
                }
                break;
            default:
                break;
        }

        this.setState({
            currentTab: tab
        })
    }

    getRecaladaTasks() {
        const { recalada } = this.props;

        this.setState({ fetching: true });

        Recaladas.getRecaladaTasks({ recaladaId: recalada.recaladaId })
            .then(response => {
                this.props.recaladaActions.setRecaladaTasks(response.data.records)

                this.setState({
                    fetching: false
                })
            }).catch(err => {
                this.setState({
                    fetching: false
                })
            })
    }

    getRecaladaEmails() {
        const { recalada } = this.props;

        this.setState({ fetching: true });

        Recaladas.getRecaladaEmails({ recaladaId: recalada.recaladaId })
            .then(response => {
                this.props.recaladaActions.setRecaladaEmails(response.data.records)

                this.setState({
                    fetching: false
                })
            }).catch(err => {
                this.setState({
                    fetching: false
                })
            })
    }

    getRecaladaDocuments() {
        const { recalada } = this.props;

        this.setState({ fetching: true });

        Recaladas.getRecaladaDocuments({ recaladaId: recalada.recaladaId })
            .then(response => {
                this.props.recaladaActions.setRecaladaDocuments(response.data.records)

                this.setState({
                    fetching: false
                })
            }).catch(err => {
                this.setState({
                    fetching: false
                })
            })
    }

    closeDetails = () => {
        const { i18n, history, currentPort } = this.props,
            lang = i18n.language;

        history.push(`/${lang}/${currentPort.code}/recaladas`)
    }

    renderContent = () => {
        const { classes } = this.props,
            { currentTab, fetching } = this.state;

        return (<>
            {fetching &&
                <div className={classes.shipDetails__content__progress}>
                    <CircularProgress />
                </div>
            }
            {!fetching && (
                <>
                    {/* Tasks */}
                    {currentTab === 0 && !fetching &&
                        <TasksTable />
                    }

                    {/* Documents */}
                    {currentTab === 1 && !fetching &&
                        <DocumentsTable />
                    }


                    {/* Emails */}
                    {currentTab === 2 && !fetching &&
                        <EmailsTable />
                    }


                    {/* Details*/}
                    {currentTab === 3 &&
                        <Details />
                    }
                </>
            )}

        </>)
    }

    render() {
        const { classes, className, t, recalada, emailsCounters } = this.props,
            { currentTab, fetching } = this.state;

        const rootClassName = classNames(classes.root, className);

        if (!recalada.recaladaId) return null

        return (
            <div className={rootClassName}>
                <Hidden xsDown implementation="css">
                    <div style={{ padding: '40px' }}>
                        <div className={classes.header__row}>
                            <div style={{ textAlign: 'center' }}>
                                <Typography variant='h4' color='textPrimary'>
                                    {`${recalada.ship.name} V-${recalada.itineraryName}`}
                                </Typography>
                                <Typography variant={'body2'} color='textPrimary'>
                                    {moment(recalada.ETA).isBefore(moment(), 'day') ? t('Arrival').toUpperCase() : t('ETA')} {moment(recalada.ETA).format('dddd DD MMMM HH:mm').toUpperCase()}
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.row}>
                            <Paper className={classes.paper}>
                                <Tabs
                                    value={currentTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={this.handleChangeTab}
                                    aria-label="disabled tabs example"
                                    classes={{
                                        root: classes.ship__tabs
                                    }}
                                >
                                    {tabsOptions.map(tabLabel => (

                                        <Tab classes={{
                                            selected: classes.selected__tab
                                        }}
                                            disabled={fetching}
                                            label={
                                                tabLabel === 'Emails' ?
                                                    <Badge badgeContent={emailsCounters && emailsCounters.unreadEmailsByRecaladaId ? (emailsCounters.unreadEmailsByRecaladaId[recalada.recaladaId] || null) : null} color="primary">
                                                        <div>
                                                            {t(tabLabel)}
                                                        </div>
                                                    </Badge>
                                                    : t(tabLabel)}
                                        />
                                    ))}
                                </Tabs>
                            </Paper>

                            <Paper style={{ minHeight: '500px', position: 'relative', padding: '24px' }}>
                                {this.renderContent()}
                            </Paper>
                        </div>
                    </div>
                </Hidden>
                <Hidden smUp implementation="css">
                    <div className={classes.header__row} style={{ paddingTop: '16px' }}>
                        <div style={{ textAlign: 'center' }}>
                            <Typography variant='h4' color='textPrimary'>
                                {`${recalada.ship ? recalada.ship.name : ''} V-${recalada.itineraryName}`}
                            </Typography>
                            <Typography variant={'body2'} color='textPrimary'>
                                {moment(recalada.ETA).isBefore(moment(), 'day') ? t('Arrival').toUpperCase() : t('ETA')} {moment(recalada.ETA).format('dddd DD MMMM HH:mm').toUpperCase()}
                            </Typography>
                        </div>
                    </div>
                    <div style={{ minHeight: '50px' }}>
                        {this.renderContent()}
                    </div>

                    <div className={classes.bottomNavigation__container}>
                        <Divider />
                        <BottomNavigation
                            value={currentTab}
                            onChange={this.handleChangeTab}
                            showLabels
                        >
                            <BottomNavigationAction
                                onClick={(e) => this.handleChangeTab(e, 0)}
                                label={t('Tasks')}
                                icon={<AssignmentOutlined />} />
                            <BottomNavigationAction
                                onClick={(e) => this.handleChangeTab(e, 1)}
                                label={t('Documents')}
                                icon={<DescriptionOutlined />} />
                            <BottomNavigationAction
                                onClick={(e) => this.handleChangeTab(e, 2)}
                                label={t("Emails")}
                                icon={
                                    <Badge badgeContent={emailsCounters && emailsCounters.unreadEmailsByRecaladaId ? (emailsCounters.unreadEmailsByRecaladaId[recalada.recaladaId] || null) : null} color="primary">
                                        <MailOutline />
                                    </Badge>
                                } />
                            <BottomNavigationAction
                                onClick={(e) => this.handleChangeTab(e, 3)}
                                label={t("Details")}
                                icon={<DirectionsBoatOutlined />} />
                        </BottomNavigation>
                    </div>
                </Hidden>
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return {
        recaladasActions: bindActionCreators(recaladasActions, dispatch),
        recaladaActions: bindActionCreators(recaladaActions, dispatch),
    }
}


function mapStateToProps(state, ownProps) {
    return {
        recaladas: state.recaladas,
        recalada: state.recalada,
        emailsCounters: state.emailsCounters,
        currentPort: state.currentPort
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(withStyles(styles)(ShipDetails))));
