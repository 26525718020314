export default theme => ({
    root: {

    },
    userAvatar: {
        borderRadius: '50%',
        backgroundColor: '#f3f3f3',
        width: 30,
        height: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(),
    },
    emails__container: {
        padding: theme.spacing(),
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f4f4f4',
        margin: `${theme.spacing(2)}px 0`,
        flexWrap: 'wrap',
    },
    email__container: {
        marginBottom: 10,
        display: 'flex',
    },
    'email__container--unread': {
        backgroundColor: '#fff'
    },
    'email__text__container--unread': {
        fontWeight: 'bold'
    },
    email__text__container: {
        marginLeft: 10
    },
    link: {
        color: theme.palette.primary.main
    },
})
