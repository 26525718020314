import React, { Component } from 'react';

// Externals
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom';

import {
    withStyles,
    Typography,
    Hidden,
    Table,
    TableBody,
    TableRow,
    TableCell,
    List,
    ListItem,
    Divider
} from '@material-ui/core';
import {
    Attachment
} from '@material-ui/icons'
import moment from "moment"

// Component styles
import styles from './styles';
import { EmailDetails } from '../../../Emails/components';
import { SearchInput } from 'components';


//Redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as recaladaActions from '../../../../actions/recaladaActions'
import { isMobile } from 'react-device-detect';

let timeout = null


class ShipDetails extends Component {

    state = {
        emails: this.props.recalada.emails,
        selectedEmail: null
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            emails: nextProps.recalada.emails,
        })
    }


    formatedDate = (date) => {
        if (moment().isSame(moment(date), 'day')) {
            return moment(date).format('hh:mm a')
        } else {
            return moment(date).format('MMM DD')
        }
    }

    handleSearch = (e) => {
        const { emails } = this.state

        this.setState({
            search: e.target.value
        }, () => {
            const { search } = this.state

            let newEmails = search ?
                [...emails].filter(em => em.recipient.toLowerCase().includes(search.toLowerCase()) || em.senderWithFormat.toLowerCase().includes(search.toLowerCase()) || em.subject.toLowerCase().includes(search.toLowerCase()) || em.bodyPlain.toLowerCase().includes(search.toLowerCase()))
                : this.props.recalada.emails

            if (timeout) {
                clearTimeout(timeout);
            }

            timeout = setTimeout(() => {

                this.setState({
                    emails: newEmails
                })

                clearTimeout(timeout);
                timeout = null;
            }, 700)
        })


    }

    handleClickEmail = (emailId) => {
        const { emails } = this.state

        this.setState({
            selectedEmail: emails.find(e => e.emailId === emailId)
        })

    }

    closeEmailDetail = () => {
        this.setState({
            selectedEmail: null
        })
    }

    render() {
        const { classes, t } = this.props,
            { fetching, emails, selectedEmail } = this.state;

        return (
            <div>
                { !selectedEmail && 
                    <div style={{ width: '450px', padding: isMobile ? '16px 0' : '0 0 16px 0'}}>
                        <SearchInput
                            className={classes.searchInput}
                            placeholder={t("Search email")}
                            onChange={this.handleSearch}
                            disabled={fetching}
                        />
                    </div>
                }
                {emails && emails.length > 0 ? <>
                    {!selectedEmail ? <>
                        <Hidden xsDown implementation="css">
                            <Table>
                                <TableBody>
                                    {emails.map((email, i) => (
                                        <TableRow
                                            className={`${classes.tableRow} ${email.read ? classes['mail--read'] : classes['mail--unread']}`}
                                            hover
                                            key={email.emailId}
                                            selected={email.emailId === (selectedEmail ? selectedEmail.emailId : null)}
                                            onClick={e => this.handleClickEmail(email.emailId)}
                                        >
                                            <TableCell className={classes.tableCell}>
                                                <div className={classes.tableCellInner}>
                                                    <Typography
                                                        className={classes.nameText}
                                                        variant="body1"
                                                        style={{
                                                            fontWeight: email.read ? 'normal' : 'bold'
                                                        }}
                                                    >
                                                        {email.senderWithFormat ? email.senderWithFormat.split('<')[0] : email.sender}
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.tableCell} style={{ maxWidth: 300 }}>
                                                <Typography noWrap><span style={{
                                                    fontWeight: email.read ? 'normal' : 'bold'
                                                }}>{email.subject}</span> - {email.bodyPlain}</Typography>
                                            </TableCell>
                                            <TableCell padding='none'>
                                                {email.attachments.length > 0 && (
                                                    <Attachment />
                                                )}
                                            </TableCell>
                                            <TableCell padding='none' align='center' style={{ paddingRight: 5 }}>
                                                {this.formatedDate(email.timestamp)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Hidden>
                        <Hidden smUp implementation="css">
                            <List
                                className={classes.list} style={{paddingTop: 0}}>
                                {emails.map(row => (
                                    <React.Fragment key={row.emailId}>
                                        <ListItem button
                                            key={row.emailId}
                                            className={`${classes.listItem} ${row.read ? classes['mail--read'] : classes['mail--unread']}`}>
                                            <div style={{
                                                width: '100%',
                                                position: 'relative'
                                            }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography noWrap
                                                        onClick={e => this.handleClickEmail(row.emailId)}
                                                        style={{
                                                            maxWidth: '80%'
                                                        }}>
                                                        <span style={{
                                                            fontWeight: row.read ? 'normal' : 'bold'
                                                        }}>
                                                            {row.senderWithFormat ? row.senderWithFormat : row.sender}
                                                        </span>
                                                    </Typography>
                                                </div>
                                                <div onClick={e => this.handleClickEmail(row.emailId)}>
                                                    <Typography noWrap
                                                        style={{
                                                            // maxWidth: '80%'
                                                        }}><span style={{
                                                            fontWeight: row.read ? 'normal' : 'bold'
                                                        }} >{row.subject}</span></Typography>
                                                    <Typography noWrap style={{
                                                        // maxWidth: '80%'
                                                    }}>{row.bodyPlain}</Typography>
                                                    {row.attachments.length > 0 && (
                                                        <Attachment style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 4
                                                        }} />
                                                    )}
                                                    <Typography style={{
                                                        position: 'absolute',
                                                        bottom: 0,
                                                        right: 4
                                                    }} noWrap>{(e) => this.formatedDate(row.timestamp)}</Typography>
                                                </div>
                                            </div>
                                        </ListItem>
                                        <Divider style={{ width: '100%', heigth: 5, margin: 0 }} />
                                    </React.Fragment>
                                ))}
                            </List>
                        </Hidden>
                    </> : 
                        <div>
                            <EmailDetails
                                isRecaladaSummary={true}
                                selectedEmail={selectedEmail}
                                back={this.closeEmailDetail}
                                updateEmails={this.updateEmails} />
                        </div>

                    }
                </> :
                    <div style={{ width: '100%', margin: '24px auto' }}>
                        <Typography>
                            {t('No emails')}
                        </Typography>
                    </div>
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        recaladaActions: bindActionCreators(recaladaActions, dispatch)
    }
}


function mapStateToProps(state, ownProps) {
    return {
        recalada: state.recalada
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(withStyles(styles)(ShipDetails))));
