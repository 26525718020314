import React, { Component, Fragment } from 'react';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';

// Material helpers
import { withStyles, withWidth } from '@material-ui/core';

// Material components
import { Drawer, CircularProgress } from '@material-ui/core';

// Custom components
import { Sidebar, Topbar, Footer } from './components';
import { ChangePortDialog } from '../../components'
import auth from '../../authentication/auth';

// Component styles
import styles from './styles';
//Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom';
import * as EmailsActions from 'actions/emailsActions'
import * as generalActions from 'actions/generalActions'
import * as portActions from 'actions/portActions'
import { General } from '../../services'

import { Helmet } from "react-helmet";

class Dashboard extends Component {
	ws = new WebSocket('wss://ari7btmjka.execute-api.us-east-1.amazonaws.com/dev')
	constructor(props) {
		super(props);

		const isMobile = ['xs', 'sm', 'md'].includes(props.width);

		this.state = {
			isOpen: !isMobile,
			openChangePort: false,
			fetching: false
		};
	}

	componentWillMount = () => {
		const { match, generalParameters } = this.props

		if (!generalParameters || !generalParameters.ports) {
			this.setState({
				fetching: true
			})
			auth.getAuthenticatedUser()
				.then(user => {
					console.log("User authenticated...")
					General.getGeneralParameters()
						.then(response => {
							this.props.generalActions.setGeneralParameters(response.data.records)

							let port = match.params.port,
								currentPort = response.data.records.ports.find(p => p.portCode === port)

							this.props.portActions.setCurrentPort({
								code: currentPort.portCode,
								name: currentPort.portName
							})

							this.setState({
								fetching: false
							})
						}).catch(err => {
							this.setState({
								fetching: false
							})
						})
				}).catch(_ => {
					this.props.history.push('/signIn');
				})
		}
	}

	componentDidMount = () => {
		//TODO: Sockets > Crear clase para conectar y escuchar sockets
		this.ws.onopen = () => {
			// on connecting, do nothing but log it to the console
			console.log('websocket connected')
		}
		this.ws.onmessage = evt => {
			// listen to data sent from the websocket server
			const data = JSON.parse(evt.data)
			// console.log(data, "socket data")
			switch (data.action) {
				case 'unreadEmailsCounters':
					delete data.action;
					this.props.EmailsActions.setEmailsCounters(data)
					break;
				default:
					break;
			}
		}
	}

	handleClose = () => {
		this.setState({ isOpen: false });
	};

	handleToggleOpen = () => {
		this.setState(prevState => ({
			isOpen: !prevState.isOpen
		}));
	};

	handleChangePort = () => {
		this.setState({
			openChangePort: !this.state.openChangePort
		})
	}

	render() {
		const { classes, width, title, children, showBackMenuButton, goBackUrl } = this.props;
		const { isOpen, openChangePort, fetching } = this.state;

		const isMobile = ['xs', 'sm', 'md'].includes(width);
		const shiftTopbar = isOpen && !isMobile;
		const shiftContent = isOpen && !isMobile;

		return (
			<Fragment>
				<Helmet>
					<title>CTSA - {title}</title>
				</Helmet>
				<Topbar
					className={classNames(classes.topbar, {
						[classes.topbarShift]: shiftTopbar
					})}
					isSidebarOpen={isOpen}
					onToggleSidebar={this.handleToggleOpen}
					title={title}
					showBackMenuButton={showBackMenuButton}
					goBackUrl={goBackUrl}
				/>
				<Drawer
					anchor="left"
					classes={{ paper: classes.drawerPaper }}
					onClose={this.handleClose}
					open={isOpen}
					variant={isMobile ? 'temporary' : 'persistent'}
				>
					<Sidebar
						className={classes.sidebar}
						onToggleSidebar={isMobile ? this.handleToggleOpen : () => { }}
						handleChangePort={this.handleChangePort}
					/>
				</Drawer>
				<main
					className={classNames(classes.content, {
						[classes.contentShift]: shiftContent
					})}
				>
					{fetching &&
						<div className={classes.progressWrapper}>
							<CircularProgress />
						</div>
					}
					{!fetching && children}
					<Footer />
				</main>

				<ChangePortDialog
					openDialog={openChangePort}
					handleCloseDialog={this.handleChangePort}
				/>
			</Fragment>
		);
	}
}

const mapStateToProps = ({ emailsCounters, generalParameters }) => ({ emailsCounters, generalParameters });
const mapDispatchToProps = (dispatch) => ({
	EmailsActions: bindActionCreators(EmailsActions, dispatch),
	generalActions: bindActionCreators(generalActions, dispatch),
	portActions: bindActionCreators(portActions, dispatch)
})


export default connect(mapStateToProps, mapDispatchToProps)(compose(
	withStyles(styles),
	withWidth(),
	withRouter
)(Dashboard))
